import React, { useState, useEffect } from 'react';
import HomeOurBooksStyles from './HomeOurBooks.module.css';
import book1Image from '../../../../Assets/BookCovers/Book1.webp';
import book2Image from '../../../../Assets/BookCovers/docterboy.webp';
import book3Image from '../../../../Assets/BookCovers/firefightergirl.webp';
import book4Image from '../../../../Assets/BookCovers/footballergirl.webp';
import book5Image from '../../../../Assets/BookCovers/youtubegirl.webp';
import book6Image from '../../../../Assets/BookCovers/vetboycover.webp';
import BookCard from '../../../../Components/BookCard/BookCard';

import book7Image from '../../../../Assets/BookCovers/teacherboy.webp';
import book8Image from '../../../../Assets/BookCovers/drivergirl.webp';
import book9Image from '../../../../Assets/BookCovers/policeboy.webp';
import book10Image from '../../../../Assets/BookCovers/nursegirl.webp';

import { GrFormPrevious } from "react-icons/gr";
import { GrFormNext } from "react-icons/gr";

const booksData = [
  { bookId: 'astronaut', title: 'I Want To Be An Astronaut',rating: 5, category: 'astronaut', price: 23.99, imageUrl: book1Image },
  { bookId: 'doctor', title: 'I Want To Be A Doctor', rating: 5,category: 'doctor', price: 23.99, imageUrl: book2Image },
  { bookId: 'firefighter', title: 'I Want To Be A Firefighter',rating: 5, category: 'firefighter', price: 23.99, imageUrl: book3Image },
  { bookId: 'footballer', title: 'I Want To Be A Footballer',rating: 5, category: 'footballer', price: 23.99, imageUrl: book4Image },
  { bookId: 'youtuber', title: 'I Want To Be A Youtuber',rating: 5, category: 'youtuber', price: 23.99, imageUrl: book5Image },
  { bookId: 'vet', title: 'I Want To Be A Vet', rating: 5,category: '', price: 23.99, imageUrl: book6Image },

  { bookId: 'traindriver', title: 'I Want To Be A Train Driver', rating: 5,category: '', price: 23.99, imageUrl: book8Image },
  { bookId: 'policeofficer', title: 'I Want To Be A Police Officer', rating: 5,category: '', price: 23.99, imageUrl: book9Image },
  { bookId: 'nurse', title: 'I Want To Be A Nurse',rating: 5, category: 'nurse', price: 23.99, imageUrl: book10Image },
  { bookId: 'teacher', title: 'I Want To Be A Teacher', rating: 5,category: '', price: 23.99, imageUrl: book7Image },

];

const HomeOurBooks = ({ titleColor }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(4);

  const updateItemsPerPage = () => {
    const width = window.innerWidth;
    if (width <= 637) {
      setItemsPerPage(1);
    } else if (width <= 992) {
      setItemsPerPage(2);
    } else if (width <= 1024) {
      setItemsPerPage(3);
    } 
    else if (width <= 1289) {
        setItemsPerPage(3);
      } 
      else {
      setItemsPerPage(4);
    }
  };

  useEffect(() => {
    updateItemsPerPage();
    window.addEventListener('resize', updateItemsPerPage);
    return () => {
      window.removeEventListener('resize', updateItemsPerPage);
    };
  }, []);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % booksData.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + booksData.length) % booksData.length);
  };

  const getDisplayedBooks = () => {
    const startIndex = currentIndex;
    const endIndex = (currentIndex + itemsPerPage) % booksData.length;
    if (endIndex > startIndex) {
      return booksData.slice(startIndex, endIndex);
    } else {
      return [...booksData.slice(startIndex), ...booksData.slice(0, endIndex)];
    }
  };

  const displayedBooks = getDisplayedBooks();

  return (
    <div className={HomeOurBooksStyles.main}>
      <h1 style={{ color: titleColor }} className={HomeOurBooksStyles.mainheading}>Our Books</h1>

      <div className={HomeOurBooksStyles.carouselContainer}>
        {/* <button className={HomeOurBooksStyles.arrow} onClick={prevSlide}>&lt;</button> */}
        <div className={HomeOurBooksStyles.prev_btn}>
                <GrFormPrevious onClick={prevSlide} className={HomeOurBooksStyles.carousalBtns} />
              </div>        <div className={HomeOurBooksStyles.carousel}>
          {displayedBooks.map((book) => (
            <div key={book.bookId} className={HomeOurBooksStyles.bookCard}>
              <BookCard product={book} />
            </div>
          ))}
        </div>
        <div className={HomeOurBooksStyles.next_btn}>
                <GrFormNext onClick={nextSlide} className={HomeOurBooksStyles.carousalBtns} />
              </div>
        {/* <button className={HomeOurBooksStyles.arrow} onClick={nextSlide}>&gt;</button> */}
      </div>
    </div>
  );
};

export default HomeOurBooks;
