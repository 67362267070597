// import React from "react";
// import ContactUsstyle from "./ComingSoon.module.css";
// import "../../fonts.css";
// import Footer from "../../Components/Footer/Footer";
// import { ReactComponent as Comingsoonsvg } from "../../Assets/SVG/ComingSoonSvg.svg";

// const ComingSoon = () => {
//   return (
//     <div className={ContactUsstyle.ContactUs}>
//       <div className={ContactUsstyle.mainContainer}>
//         <h1 className={ContactUsstyle.heading}>Coming Soon</h1>
//         <div className={ContactUsstyle.content}>
//           <p className={ContactUsstyle.paragraph}>
//           We’re thrilled to have you drop by our website would LOVE to hear from you.
//           Whether it’s an idea for a new book, some feedback on our website,
//           or simply just to say “hi” then use the form below or email us at 
//           </p>
//         </div>
//         <div className={ContactUsstyle.main}>
//             <div className={ContactUsstyle.form}>
//               <div className={ContactUsstyle.row}>
//                 <div className={ContactUsstyle.field}>
//                   <label htmlFor="firstName">First Name</label>
//                   <input type="text" id="firstName" name="firstName" />
//                 </div>
//                 <div className={ContactUsstyle.field}>
//                   <label htmlFor="lastName">Last Name</label>
//                   <input type="text" id="lastName" name="lastName" />
//                 </div>
//               </div>
//               <div className={ContactUsstyle.row}>
//                 <div className={ContactUsstyle.field}>
//                   <label htmlFor="email">Email</label>
//                   <input type="email" id="email" name="email" />
//                 </div>
//               </div>
//               <div className={ContactUsstyle.row}>
//                 <div className={ContactUsstyle.field}>
//                   <label htmlFor="email">Book Name</label>
//                   {/* <input type="email" id="email" name="email" /> */}
//                   <div className={ContactUsstyle.selectContainer}>
//                     <select name="bookName" id="">
//                       <option value="">Police Officer</option>
//                     </select>
//                   </div>
//                 </div>
//               </div>
//               <div className={ContactUsstyle.row}>
//                 <div className={ContactUsstyle.field}>
//                   <label htmlFor="message">Message</label>
//                   <textarea id="message" name="message"></textarea>
//                 </div>
//               </div>
//               <div className={ContactUsstyle.row}>
//                 {/* <button type="submit" className={ContactUsstyle.sendButton}></button> */}
//                 <Comingsoonsvg
//                   type="submit"
//                   className={ContactUsstyle.sendButton}
//                 />
//               </div>
//             </div>
//           </div>
//         <div className={ContactUsstyle.HomeContactus}>

//           <div className={ContactUsstyle.Footer}>
//             <Footer />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ComingSoon;





import React, { useState } from "react";
import ContactUsstyle from "./ComingSoon.module.css";
import "../../fonts.css";
import Footer from "../../Components/Footer/Footer";
import { ReactComponent as Comingsoonsvg } from "../../Assets/SVG/ComingSoonSvg.svg";
import { useLocation } from "react-router-dom";
import book7Image from '../../Assets/BookCovers/teacherboy.webp';
import book8Image from '../../Assets/BookCovers/drivergirl.webp';
import book9Image from '../../Assets/BookCovers/policeboy.webp';
import book10Image from '../../Assets/BookCovers/nursegirl.webp';
import { post } from 'aws-amplify/api';


const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
const ComingSoon = () => {
  const location = useLocation();
  const RouteimgURL = location.state?.imgURL; // Access the passed state
  const RouteProduct = location.state?.routeProduct; // Access the passed state
  const newTitle = RouteProduct?.title?.replace("I Want To Be A ", "");


  const [fields, setFields] = useState({
    firstName: "",
    lastName: "",
    email: "",
    bookName: newTitle || "Police Officer",
    message: ""
  })

  

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    bookName: "",
    message: ""
  })
  

  const handleInputChange = (e) => {

    setErrors({
      firstName: "",
      lastName: "",
      email: "",
      bookName: "",
      message: ""
    })

    setFields((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }))
  }

  const handleErrors = (key, value) => {
    setErrors((prev) => ({
      ...prev,
      [key]: value
    }))
  }

  const handleSubmit = async () => {
    let bol = true
    if (!fields.firstName) {
      handleErrors("firstName", "First Name Cannot be empty")
      bol = false
    }
    else {
      handleErrors("firstName", "")
      bol = true
    }

    if (!fields.lastName) {
      handleErrors("lastName", "Last Name Cannot be empty")
      bol = false
    }
    else {
      handleErrors("lastName", "")
      bol = true
    }

    if (!fields.email) {
      handleErrors("email", "Email Cannot be empty")
      bol = false
    }
    else {
      handleErrors("email", "")
      bol = true
    }

    if (!fields.message) {
      handleErrors("message", "Message Cannot be empty")
      bol = false
    }
    else {
      handleErrors("message", "")
      bol = true
    }
    if (!fields.bookName) {
      handleErrors("bookName", "Book Name Cannot be empty")
      bol = false
    }
    else {
      handleErrors("bookName", "")
      bol = true
    }
    if (!bol) {
      return
    }


    try {
      const response = await fetch(`${apiBaseUrl}/items/contact-us`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          firstname: fields.firstName,
          lastname: fields.lastName,
          email: fields.email,
          subject: fields.bookName,
          message: fields.message,
        })
      });
      // const response = post({
      //   apiName: 'apiBooks321',
      //   path: '/items/contact-us',
      //   options: {
      //     headers: {
      //       'Content-Type': 'application/json',
      //   },
      //     body: JSON.stringify({
      //     firstname: fields.firstName,
      //     lastname: fields.lastName,
      //     email: fields.email,
      //     subject: fields.bookName,
      //     message: fields.message,
      //   })
      //   }
      // });
      if (response.status === 200) {
        setFields({
          firstName: "",
          lastName: "",
          email: "",
          message: "",
          bookName: "Police Officer",
        })
      }
     

    } catch (error) {
      console.log("error", error)
    }
  }
  return (
    <div className={ContactUsstyle.ContactUs}>
      <div className={ContactUsstyle.mainContainer}>
        <h1 className={ContactUsstyle.heading}>Coming Soon</h1>

        <div className={ContactUsstyle.content}>
          <p className={ContactUsstyle.paragraph}>
            {/* We’re thrilled to have you drop by our website. would LOVE to hear from you.
            Whether it’s an idea for a new book, some feedback on our website,
            or simply just to say “hi” then use the form below or email us at */}
            Thank you for selecting this wonderful story, it will be available to customise in a few days. If you would like to receive a short message when it is ready, then fill out the form below and we'll be sure to let you know Thanks!
            <br />The I Want to Be team
          </p>
        </div>
        {RouteimgURL && (
          <div style={{ display: "flex", marginTop: "20px" }}>
            <img className={ContactUsstyle.RouteimgURL} src={RouteimgURL} alt="RouteimgURL" />
          </div>
        )}
        {!RouteimgURL && (
          <div style={{
            display: 'flex',
            flexWrap: 'wrap', /* Allows items to wrap to the next line */
            justifyContent: 'center', /* Centers items horizontally */
            marginTop: '20px',
            padding: '5px',
            gap: '14px',
            margin: '10px'
          }}>
            <img className={ContactUsstyle.RouteimgURL} src={book7Image} alt="RouteimgURL"/>
            <img className={ContactUsstyle.RouteimgURL} src={book8Image} alt="RouteimgURL"/>
            <img className={ContactUsstyle.RouteimgURL} src={book9Image} alt="RouteimgURL"/>
            <img className={ContactUsstyle.RouteimgURL} src={book10Image} alt="RouteimgURL"/>
          </div>
          

        )}
        <div className={ContactUsstyle.main}>
          <div className={ContactUsstyle.form}>
            <div className={ContactUsstyle.row}>
              <div className={ContactUsstyle.field}>
                <label htmlFor="firstName">First Name</label>
                <input type="text" id="firstName" name="firstName" onChange={handleInputChange} value={fields.firstName} />
                {errors.firstName && <p className={ContactUsstyle.error}>{errors.firstName}</p>}

              </div>
              <div className={ContactUsstyle.field}>
                <label htmlFor="lastName">Last Name</label>
                <input type="text" id="lastName" name="lastName" onChange={handleInputChange} value={fields.lastName} />
                {errors.lastName && <p className={ContactUsstyle.error}>{errors.lastName}</p>}

              </div>
            </div>
            <div className={ContactUsstyle.row}>
              <div className={ContactUsstyle.field}>
                <label htmlFor="email">Email</label>
                <input type="email" id="email" name="email" onChange={handleInputChange} value={fields.email} />
                {errors.email && <p className={ContactUsstyle.error}>{errors.email}</p>}

              </div>
            </div>
            <div className={ContactUsstyle.row}>
              <div className={ContactUsstyle.field}>
                <label htmlFor="email">Book Name</label>
                {/* <input type="email" id="email" name="email" /> */}
                <div className={ContactUsstyle.selectContainer}>
                  <select name="bookName" id="bookName" onChange={handleInputChange} value={fields.bookName}>
                    <option className={ContactUsstyle.optioncls} value="Police Officer" selected>Police Officer</option>
                    <option className={ContactUsstyle.optioncls} value="Doctor" selected>Doctor</option>
                    <option className={ContactUsstyle.optioncls} value="Astronaut" selected>Astronaut</option>
                    <option className={ContactUsstyle.optioncls} value="Fire Fighter" selected>Fire Fighter</option>
                    <option className={ContactUsstyle.optioncls} value="Nurse" selected>Nurse</option>
                    <option className={ContactUsstyle.optioncls} value="Vet" selected>Vet</option>
                    <option className={ContactUsstyle.optioncls} value="Train Driver" selected>Train Driver</option>
                    <option className={ContactUsstyle.optioncls} value="Teacher" selected>Teacher</option>
                    <option className={ContactUsstyle.optioncls} value="Youtuber" selected>Youtuber</option>
                    <option className={ContactUsstyle.optioncls} value="Footballer" selected>Footballer</option>

                  </select>
                </div>
                {errors.bookName && <p className={ContactUsstyle.error}>{errors.bookName}</p>}

              </div>
            </div>
            <div className={ContactUsstyle.row}>
              <div className={ContactUsstyle.field}>
                <label htmlFor="message">Message</label>
                <textarea id="message" name="message" onChange={handleInputChange} value={fields.message}></textarea>
              </div>
            </div>
            {errors.message && <p className={ContactUsstyle.error}>{errors.message}</p>}

            <div onClick={handleSubmit} className={ContactUsstyle.row}>
              {/* <button type="submit" className={ContactUsstyle.sendButton}></button> */}
              <Comingsoonsvg
                type="submit"
                className={ContactUsstyle.sendButton}
              />
            </div>
          </div>
        </div>
        <div className={ContactUsstyle.HomeContactus}>

          <div className={ContactUsstyle.Footer}>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;