import './App.css';
import Home from './Pages/Home/Home';
import Navbar from './Components/Navbar/Navbar';
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import PrivacyPolicy from './Pages/PrivacyPolicy/PrivacyPolicy';
import ContactUs from './Pages/ContactUs/ContactUs';
import ShippingAddress from './Pages/Order/ShippingAddress/ShippingAddress';
import CreateYourCharacter from './Pages/CreateYourCharacter/CreateYourCharacter';
import Bookcategories from './Pages/Bookcategories/Bookcategories';
import SigninSignup from './Pages/SigninSignup/SigninSignup';
import CartItems from "./Pages/Cart/CartItems"
import Profile from './Pages/Profile/Profile';
import OurBooksMain from './Pages/OurBooksMain/OurBooksMain';
import { useEffect, useRef, useState } from 'react';
import OurStory from './Pages/OurStory/OurStory';
import Cart from './Pages/Cart/Cart';
import ComingSoon from './Pages/ComingSoon/ComingSoon';

import { Hub } from "aws-amplify/utils";
import { getCurrentUser } from "aws-amplify/auth";
import { useApi } from './context/ApiContext';

import Button from '@mui/joy/Button';
import Snackbar from '@mui/joy/Snackbar';

import { useDispatch, useSelector } from 'react-redux';
import '@fontsource/inter';
import { settoaststate } from './features/toastSlice';
import { MySnackbar } from './Components/MySnackbar/MySnackbar';
import { useUser } from './context/UserContext';
import Payment from './Payment/Payment';

function App() {
  const location = useLocation();

  const { uniqueId,toaststate, size, variant, color,  message, iCon } = useSelector((state) => state.toastSliceInfo);
  const dispatch = useDispatch();
  // const [scrollPosition, setScrollPosition] = useState(0);
  const [user, setUser] = useState(localStorage.getItem("bookstorecurrentloginuser") || null);

  const containerRefscroll = useRef(null);
  const { fullName } = useUser();

  useEffect(() => {
    if (containerRefscroll.current) {
      containerRefscroll.current.scrollTop = 0;
    }
  }, [location.pathname]);

  useEffect(() => {
    const checkUser = async () => {
      try {
        const authUser = await getCurrentUser();
        setUser(authUser.userId);
        // console.log("authUser",authUser)
      } catch (error) {
        setUser(null);
      }
    };

    checkUser();

    // Subscribe to authentication events to automatically update user state
    Hub.listen("auth", ({ payload }) => {
      if (payload.event === "signIn") {
        checkUser();
      }
      if (payload.event === "signOut") {
        setUser(null);
      }
    });
  }, []);

  // const { books, otherData, loading, error } = useApi();

  // if (loading) return <div>Loading...</div>;
  // if (error) return <div>Error: {error}</div>;

  // console.log("books",books)
  // console.log("otherData",otherData)
  
  return (
    // <Router>
      
    <div className="App" >
      <div className='navbarmain'>
        <Navbar fullName={fullName} />
      </div>
      <div className='mainContentDiv' ref={containerRefscroll}>
        <Routes>
          <Route path="/" element={<Home />} />
          {/* Add more routes here */}
          <Route path='/privacypolicy' element={<PrivacyPolicy />} />
          <Route path='/contactus' element={<ContactUs/>} />
          <Route path='/shippingaddress' element={<ShippingAddress/>} />
          <Route path='/createyourcharacter' element={<CreateYourCharacter/>} />
          <Route path='/our-books/category/:category' element={<Bookcategories/>} />

          {!user && <Route path='/login' element={<SigninSignup/>} />}
          {user && <Route path="/login" element={<Navigate to="/" />} />}
          {user && <Route path='/profile' element={<Profile  fullName={fullName}/>} />}
          {!user && <Route path="/profile" element={<Navigate to="/" />} />}
          {/* <Route path='/cart' element={<CartItems />} /> */}
          <Route path='/our-books' element={<OurBooksMain />} />
          <Route path='/our-story' element={<OurStory />} />
          <Route path='/cart' element={<Cart />} />
          <Route path='*' element={<ComingSoon />} />
          <Route path='/coming-soon' element={<ComingSoon />} />

          <Route path='/payment' element={<Payment/>}/>
        </Routes>

        {/* toaststate, size, variant, color,  message,settoaststate */}

        <MySnackbar
          key={uniqueId} // Ensure unique ID
          variant={variant}
          color={color}
          toaststate={toaststate}
          message={message}
          size={size}
          iCon={iCon}
        />
      </div>
    </div>
    // </Router>
  );
}

export default App;