import React from 'react';
import reviewstyles from './Reviews.module.css';

const generateStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      if (i <= Math.floor(rating)) {
        stars.push(<span key={i} className={`${reviewstyles.star} ${reviewstyles.full}`}>★</span>);
      } else if (i === Math.ceil(rating) && rating % 1 !== 0) {
        stars.push(<span key={i} className={`${reviewstyles.star} ${reviewstyles.half}`}>★</span>);
      } else {
        stars.push(<span key={i} className={reviewstyles.star}>★</span>);
      }
    }
    return stars;
  };
  
const Reviews = ({ CustomersReviews, reviewscolor }) => {
  return (
    <div className={reviewstyles.main}>
      <h1 style={{color: reviewscolor}} className={reviewstyles.heading}>Reviews</h1>
      <div>
        {CustomersReviews && CustomersReviews.map((review, index) => (
          <div key={index} className={reviewstyles.reviewContainer}>
            <div className={reviewstyles.row}>
                <div className={reviewstyles.reviewName}>{review.name}</div>
                {/* <div className={reviewstyles.reviewStars}>
                    {'★'.repeat(Math.floor(review.start)) + (review.start % 1 !== 0 ? '½' : '')}
                </div> */}
                <div className={reviewstyles.reviewStars}>
                    {generateStars(review.start)}
                </div>
            </div>
            
            <div className={reviewstyles.reviewText}>{review.review}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Reviews;
