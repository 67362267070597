import React from 'react'
import PrivacyPolicystyle from './PrivacyPolicy.module.css'
import '../../fonts.css';
import Footer from '../../Components/Footer/Footer';
import microscpe from '../../Assets/Images/Bookcategories/Astronaut/doorbean.webp';

const PrivacyPolicy = () => {
  return (
    <div className={PrivacyPolicystyle.PrivacyPolicy}>
        <div style={{position:'relative', width: '100%', height: '100%'}}>
        <div className={PrivacyPolicystyle.mainContainer}>
            
            <h1 className={PrivacyPolicystyle.heading}>Privacy Policy for "I Want to Be</h1>
            <div className={PrivacyPolicystyle.content}>
                <p className={PrivacyPolicystyle.paragraph}>
                    Welcome to "I Want to Be"! Your privacy is critical to us.
                    This privacy policy explains how we collect, use, and
                    protect the personal information of our website's visitors
                    and users. This policy also describes the choices you can
                    make about our collection and use of your information.
                </p>
                <h4 className={PrivacyPolicystyle.subheading}>Information We Collect</h4>
                <ul className={PrivacyPolicystyle.uolist}>
                    <li>
                        <span className={PrivacyPolicystyle.uolistheading}>Personal Information:</span><br/>
                        We collect personal information that you provide to us.
                        This includes names, addresses, email addresses, and
                        any other details submitted when creating a 
                        personalized book.
                    </li>
                    <li>
                        <span className={PrivacyPolicystyle.uolistheading}>Children's Information:</span><br/>
                        In compliance with the Children's Online Privacy
                        Protection Act (COPPA), we collect information about
                        children only as provided by the parent or guardian.
                        We do not knowingly collect personal information from
                        children under the age of 13 without parental consent.
                    </li>
                </ul>
                <h4 className={PrivacyPolicystyle.subheading}>How We Use Your Information</h4>
                <ul className={PrivacyPolicystyle.uolist}>
                    <li>To personalize and fulfill the product you have requested.</li>
                    <li>To communicate with you about your order.</li>
                    <li>To enhance our website and services based on user feedback.</li>
                </ul>
                <h4 className={PrivacyPolicystyle.subheading}>Sharing Your Information</h4>
                <ul className={PrivacyPolicystyle.uolist}>
                    <li>
                        We don't sell, trade, or rent users' personal information. We may disclose information if legally required or to safeguard rights and security.
                    </li>
                </ul>
            </div>
            
            <div className={PrivacyPolicystyle.HomeContactus}>

            <div className={PrivacyPolicystyle.Footer}>
                <Footer/>
            </div>
            </div>
        </div>
        <div className={PrivacyPolicystyle.microscope}>
                <img src={microscpe} alt='microscpe'/>
            </div>
        </div>
    </div>
  )
}

export default PrivacyPolicy