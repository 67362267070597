import React, { useRef, useState } from "react";
import "../../fonts.css";
import signinsignupstyles from "./SigninSignup.module.css";
import Footer from "../../Components/Footer/Footer";
import {
  signUp,
  signIn,
  getCurrentUser,
  fetchAuthSession,
} from "aws-amplify/auth";
import OTPConfirmationModel from "./Component/OTPConfirmationModel";
import { useNavigate } from "react-router-dom";
import BookLoader from "../../Components/BookLoader/BookLoader";
import ResetPassword from "./Component/ResetPassword";
import { useDispatch } from "react-redux";
import {
  setcolor,
  setmessage,
  setsize,
  settoaststate,
  setvariant,
} from "../../features/toastSlice";
import { styled } from "@mui/material/styles";
import { TextField, InputAdornment } from "@mui/material";
import { BiHide } from "react-icons/bi";
import { IoMdEye } from "react-icons/io";
import { useUser } from "../../context/UserContext";

const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      outline: "none",
      border: "1.7px solid #bbbbbb",
      padding: "0.8rem",
      borderRadius: "24px",
      margin: "0.2rem 0",
    },
    "& input::placeholder": {
      fontSize: "13px",
    },
  },
});

const SigninSignup = () => {
  const [isSignin, setIsSignin] = useState(true);
  const [error, setError] = useState("");
  const [showotpmodel, setOtpmodel] = useState(false); //otp model state
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { setFullName } = useUser();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  // const [formDatavalidation, setFormDatavalidation] = useState({
  //   firstNamevalidation: false,
  //   lastNamevalidation: false,
  //   emailvalidation: false,
  //   passwordvalidation: false,
  //   confirmPasswordvalidation: false,
  // })

  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();

  const [forgotPasswordClicked, setForgotPasswordClicked] = useState(false);
  const [showSignupPassword, setShowSignupPassword] = useState(false);
  const [showSignupConfirmPassword, setShowSignupConfirmPassword] =
    useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setErrors({});
    setFormData({ ...formData, [name]: value });
  };

  const handleloginInputChange = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
  };

  const handleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleShowSignupPassword = () => {
    setShowSignupPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleShowSingupConfirmPassword = () => {
    setShowSignupConfirmPassword((prevShowPassword) => !prevShowPassword);
  };

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const errors = {};
    if (!formData.firstName) errors.firstName = "First name is required";
    if (!formData.lastName) errors.lastName = "Last name is required";
    if (!formData.email) errors.email = "Email is required";
    if (!formData.password) errors.password = "Password is required";
    if (formData.password !== formData.confirmPassword)
      errors.confirmPassword = "Passwords do not match";
    if (formData.password.length < 8)
      errors.password = "Password should be at least 8 characters";
    return errors;
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length > 0) return;

    setLoading(true);
    try {
      if (formData.password !== formData.confirmPassword) {
        setError("Passwords do not match");
        // console.log("Passwords do not match");
        return;
      }
      if (formData.password.length < 8) {
        setError("failure", "Password Should be At Least 8 Characters");
        // console.log("failure", "Password Should be At Least 8 Characters");
        return;
      }

      const { isSignUpComplete, userId, nextStep } = await signUp({
        username: formData.email,
        password: formData.password,
        options: {
          userAttributes: {
            // email:formData.email,
            "custom:firstName": formData.firstName,
            "custom:lastName": formData.lastName,
            // 'custom:imageurl': 'https://bookstorea8a04-dev.s3.eu-west-2.amazonaws.com/public/users/plaltform.png',
          },
          autoSignIn: true,
        },
      });
      setOtpmodel(true);

      setLoading(false);
      // alert(response.message);
    } catch (err) {
      console.error("Registration error:", err);
      setError(err.message);
    }
    setLoading(false);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { email, password } = loginData;
      // const response = await loginUser(loginData);
      const user = await signIn({
        username: email,
        password,
        //   options: {
        //   authFlowType: 'CUSTOM_WITH_SRP'
        // }
      });
      if (user) {
        const authUser = await getCurrentUser();
        const userData = await fetchAuthSession();

        localStorage.setItem(
          "bookstorecurrentusername",
          authUser.signInDetails.loginId
        );
        localStorage.setItem("bookstorecurrentloginuser", authUser.userId);

        // console.log("userData", userData?.tokens?.idToken?.payload)
        const payload = userData?.tokens?.idToken?.payload;
        const firstName = payload["custom:firstName"];
        const lastName = payload["custom:lastName"];

        const fullName = `${firstName.trim()} ${lastName}`;
        localStorage.setItem("CurrentFullName", fullName);
        setFullName(fullName);

        setTimeout(() => {
          window.location.reload();
          navigate("/");
        }, 50);
      }
      setLoading(false);
      // console.log("Login success:", user);
      // alert("Login successful",user);
    } catch (err) {
      console.error("Login error:", err);
      setError(err.message);
      dispatch(settoaststate(true));
      dispatch(setsize("sm"));
      dispatch(setvariant("solid"));
      dispatch(setcolor("danger"));
      dispatch(setmessage(err.message));
    }
    setLoading(false);
  };

  const handleForgotPasswordClick = () => {
    setForgotPasswordClicked(true);
  };

  return (
    <>
      {loading && <BookLoader />}

      {/* {!loading &&  */}
      <div className={signinsignupstyles.main}>
        <div className={signinsignupstyles.top}>
          <h1 className={signinsignupstyles.heading}>
            {isSignin ? "Log In" : "Sign Up"}
          </h1>
          <div className={signinsignupstyles.toggle}>
            <span
              className={`${signinsignupstyles.common} ${
                isSignin
                  ? signinsignupstyles.active
                  : signinsignupstyles.notactive
              }`}
              onClick={() => {
                setIsSignin(true);
                setErrors({});
                setError("");
              }}
            >
              Sign In
            </span>
            <span
              className={`${signinsignupstyles.common} ${
                !isSignin
                  ? signinsignupstyles.active
                  : signinsignupstyles.notactive
              }`}
              onClick={() => {
                setIsSignin(false);
                setErrors({});
                setError("");
              }}
            >
              Sign Up
            </span>
          </div>
          {isSignin && (
            <form onSubmit={handleLogin} className={signinsignupstyles.form}>
              <div className={signinsignupstyles.UserProfilemain}></div>
              {/* <input placeholder="Email Address" className={signinsignupstyles.inputcls}/> */}
              {/* <input
                name="email"
                placeholder="Email Address"
                className={signinsignupstyles.inputcls}
                onChange={handleloginInputChange}
              /> */}
              <div className={signinsignupstyles.inputfieldGroup}>
                <CssTextField
                  variant="outlined"
                  fullWidth
                  value={loginData.email}
                  name="email"
                  placeholder="Email Address"
                  onChange={handleloginInputChange}
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4.99984 3.33331H14.9998C16.8408 3.33331 18.3332 4.8257 18.3332 6.66665V14.1666C18.3332 16.0076 16.8408 17.5 14.9998 17.5H4.99984C3.15889 17.5 1.6665 16.0076 1.6665 14.1666V6.66665C1.6665 4.8257 3.15889 3.33331 4.99984 3.33331ZM11.1665 11.2916L16.4498 7.37498C16.6856 7.20164 16.7377 6.87067 16.5665 6.63331C16.4849 6.51999 16.3609 6.44452 16.2227 6.42411C16.0846 6.40371 15.9441 6.44009 15.8332 6.52498L10.4915 10.4166C10.3578 10.5567 10.1726 10.636 9.979 10.636C9.78537 10.636 9.60017 10.5567 9.4665 10.4166L4.1665 6.52498C4.05615 6.44276 3.91756 6.40797 3.78146 6.4283C3.64536 6.44864 3.523 6.52243 3.4415 6.63331C3.26852 6.86851 3.31681 7.19908 3.54984 7.37498L8.79984 11.25C9.12652 11.5599 9.55792 11.7354 10.0082 11.7416C10.4369 11.7416 10.8501 11.581 11.1665 11.2916Z"
                            fill="#70a3a9"
                          />
                        </svg>
                      </InputAdornment>
                    ),
                  }}
                />

                {/* <input
                name="password"
                placeholder="password"
                className={signinsignupstyles.inputcls}
                type="password"
                onChange={handleloginInputChange}
              /> */}

                <CssTextField
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  value={loginData.password}
                  onChange={handleloginInputChange}
                  required
                  name="password"
                  fullWidth
                  autoComplete="new-password"
                  variant="outlined"
                  // error={loginError}
                  // helperText={loginError && loginError}

                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M15.1721 6.52854V3.66854C15.1726 2.92138 14.869 2.20618 14.3313 1.68746C13.7935 1.16873 13.0679 0.891143 12.3212 0.918538H9.31456C8.5679 0.891143 7.84224 1.16873 7.3045 1.68746C6.76675 2.20618 6.46322 2.92138 6.46372 3.66854V6.52854C4.56731 7.20009 3.41384 9.12254 3.71372 11.1119L4.58456 16.401C5.00316 18.5274 6.89125 20.0441 9.05789 19.9944H12.6146C14.7812 20.0441 16.6693 18.5274 17.0879 16.401L17.9587 11.1119C18.2541 9.1125 17.0831 7.18641 15.1721 6.52854ZM11.7346 13.6877L11.9271 14.256C12.0115 14.5162 11.9674 14.8009 11.8082 15.0233C11.6491 15.2458 11.3938 15.3794 11.1204 15.3835H10.4421C10.1686 15.3794 9.91334 15.2458 9.75421 15.0233C9.59508 14.8009 9.55099 14.5162 9.63539 14.256L9.82789 13.6877C9.93168 13.3666 9.85069 13.0145 9.61706 12.771C9.09762 12.1539 9.14999 11.2387 9.73643 10.6849C10.3229 10.131 11.2396 10.131 11.826 10.6849C12.4125 11.2387 12.4648 12.1539 11.9454 12.771C11.7118 13.0145 11.6308 13.3666 11.7346 13.6877ZM7.83872 6.21687H8.16872V6.22604H13.7971V3.66854C13.7624 2.8872 13.1027 2.28083 12.3212 2.31187H9.31456C8.53307 2.28083 7.87343 2.8872 7.83872 3.66854V6.21687Z"
                            fill="#70a3a9"
                          />
                        </svg>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <div className={signinsignupstyles["iconDivLock"]}>
                          {showPassword ? (
                            <BiHide
                              onClick={handleShowPassword}
                              className={signinsignupstyles["eye-icon"]}
                            />
                          ) : (
                            <IoMdEye
                              onClick={handleShowPassword}
                              className={signinsignupstyles["eye-icon"]}
                            />
                          )}
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              {/* <input placeholder="Password" className={signinsignupstyles.inputcls}/> */}
              <span
                className={signinsignupstyles.Forgotpassword}
                onClick={handleForgotPasswordClick}
              >
                Forgot password?
              </span>

              {/* onClick={handleLogin} */}
              <div className={signinsignupstyles.btn}>
                <button className={signinsignupstyles.styledButton}>
                  <div className={signinsignupstyles.innerButton}>Log In</div>
                </button>
              </div>
            </form>
          )}
          {!isSignin && (
            <form
              onSubmit={handleRegister}
              style={{ gap: "10px" }}
              className={signinsignupstyles.form}
            >
              <div className={signinsignupstyles.UserProfilemain}></div>
              <div className={signinsignupstyles.firstlastname}>
                <div className={signinsignupstyles.inputsignupclass}>
                  <CssTextField
                    variant="outlined"
                    fullWidth
                    name="firstName"
                    placeholder="First Name"
                    type="text"
                    onChange={handleInputChange}
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M16.6401 22H7.36009C6.34927 21.9633 5.40766 21.477 4.79244 20.6742C4.17722 19.8713 3.95266 18.8356 4.18009 17.85L4.42009 16.71C4.69613 15.1668 6.02272 14.0327 7.59009 14H16.4101C17.9775 14.0327 19.3041 15.1668 19.5801 16.71L19.8201 17.85C20.0475 18.8356 19.823 19.8713 19.2077 20.6742C18.5925 21.477 17.6509 21.9633 16.6401 22Z"
                              fill="#70a3a9"
                            />
                            <path
                              d="M12.5001 12H11.5001C9.29096 12 7.50009 10.2092 7.50009 8.00001V5.36001C7.49743 4.46807 7.85057 3.61189 8.48127 2.98119C9.11197 2.35049 9.96815 1.99735 10.8601 2.00001H13.1401C14.032 1.99735 14.8882 2.35049 15.5189 2.98119C16.1496 3.61189 16.5028 4.46807 16.5001 5.36001V8.00001C16.5001 9.06088 16.0787 10.0783 15.3285 10.8284C14.5784 11.5786 13.561 12 12.5001 12Z"
                              fill="#70a3a9"
                            />
                          </svg>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {errors.firstName && (
                    <span className={signinsignupstyles.error}>
                      {errors.firstName}
                    </span>
                  )}
                </div>
                <div className={signinsignupstyles.inputsignupclass}>
                  <CssTextField
                    variant="outlined"
                    fullWidth
                    name="lastName"
                    type="text"
                    placeholder="Last Name"
                    onChange={handleInputChange}
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M16.6401 22H7.36009C6.34927 21.9633 5.40766 21.477 4.79244 20.6742C4.17722 19.8713 3.95266 18.8356 4.18009 17.85L4.42009 16.71C4.69613 15.1668 6.02272 14.0327 7.59009 14H16.4101C17.9775 14.0327 19.3041 15.1668 19.5801 16.71L19.8201 17.85C20.0475 18.8356 19.823 19.8713 19.2077 20.6742C18.5925 21.477 17.6509 21.9633 16.6401 22Z"
                              fill="#70a3a9"
                            />
                            <path
                              d="M12.5001 12H11.5001C9.29096 12 7.50009 10.2092 7.50009 8.00001V5.36001C7.49743 4.46807 7.85057 3.61189 8.48127 2.98119C9.11197 2.35049 9.96815 1.99735 10.8601 2.00001H13.1401C14.032 1.99735 14.8882 2.35049 15.5189 2.98119C16.1496 3.61189 16.5028 4.46807 16.5001 5.36001V8.00001C16.5001 9.06088 16.0787 10.0783 15.3285 10.8284C14.5784 11.5786 13.561 12 12.5001 12Z"
                              fill="#70a3a9"
                            />
                          </svg>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {errors.lastName && (
                    <span className={signinsignupstyles.error}>
                      {errors.lastName}
                    </span>
                  )}
                </div>
              </div>

              <div>
                <CssTextField
                  variant="outlined"
                  fullWidth
                  name="email"
                  type="email"
                  placeholder="Email Address"
                  onChange={handleInputChange}
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4.99984 3.33331H14.9998C16.8408 3.33331 18.3332 4.8257 18.3332 6.66665V14.1666C18.3332 16.0076 16.8408 17.5 14.9998 17.5H4.99984C3.15889 17.5 1.6665 16.0076 1.6665 14.1666V6.66665C1.6665 4.8257 3.15889 3.33331 4.99984 3.33331ZM11.1665 11.2916L16.4498 7.37498C16.6856 7.20164 16.7377 6.87067 16.5665 6.63331C16.4849 6.51999 16.3609 6.44452 16.2227 6.42411C16.0846 6.40371 15.9441 6.44009 15.8332 6.52498L10.4915 10.4166C10.3578 10.5567 10.1726 10.636 9.979 10.636C9.78537 10.636 9.60017 10.5567 9.4665 10.4166L4.1665 6.52498C4.05615 6.44276 3.91756 6.40797 3.78146 6.4283C3.64536 6.44864 3.523 6.52243 3.4415 6.63331C3.26852 6.86851 3.31681 7.19908 3.54984 7.37498L8.79984 11.25C9.12652 11.5599 9.55792 11.7354 10.0082 11.7416C10.4369 11.7416 10.8501 11.581 11.1665 11.2916Z"
                            fill="#70a3a9"
                          />
                        </svg>
                      </InputAdornment>
                    ),
                  }}
                />

                {errors.email && (
                  <span className={signinsignupstyles.error}>
                    {errors.email}
                  </span>
                )}
                {error === "User already exists" && (
                  <span className={signinsignupstyles.error}>{error}</span>
                )}
              </div>

              <div>
                <CssTextField
                  type={showSignupPassword ? "text" : "password"}
                  placeholder="Password"
                  onChange={handleInputChange}
                  required
                  name="password"
                  fullWidth
                  autoComplete="new-password"
                  variant="outlined"
                  // error={loginError}
                  // helperText={loginError && loginError}

                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M15.1721 6.52854V3.66854C15.1726 2.92138 14.869 2.20618 14.3313 1.68746C13.7935 1.16873 13.0679 0.891143 12.3212 0.918538H9.31456C8.5679 0.891143 7.84224 1.16873 7.3045 1.68746C6.76675 2.20618 6.46322 2.92138 6.46372 3.66854V6.52854C4.56731 7.20009 3.41384 9.12254 3.71372 11.1119L4.58456 16.401C5.00316 18.5274 6.89125 20.0441 9.05789 19.9944H12.6146C14.7812 20.0441 16.6693 18.5274 17.0879 16.401L17.9587 11.1119C18.2541 9.1125 17.0831 7.18641 15.1721 6.52854ZM11.7346 13.6877L11.9271 14.256C12.0115 14.5162 11.9674 14.8009 11.8082 15.0233C11.6491 15.2458 11.3938 15.3794 11.1204 15.3835H10.4421C10.1686 15.3794 9.91334 15.2458 9.75421 15.0233C9.59508 14.8009 9.55099 14.5162 9.63539 14.256L9.82789 13.6877C9.93168 13.3666 9.85069 13.0145 9.61706 12.771C9.09762 12.1539 9.14999 11.2387 9.73643 10.6849C10.3229 10.131 11.2396 10.131 11.826 10.6849C12.4125 11.2387 12.4648 12.1539 11.9454 12.771C11.7118 13.0145 11.6308 13.3666 11.7346 13.6877ZM7.83872 6.21687H8.16872V6.22604H13.7971V3.66854C13.7624 2.8872 13.1027 2.28083 12.3212 2.31187H9.31456C8.53307 2.28083 7.87343 2.8872 7.83872 3.66854V6.21687Z"
                            fill="#70a3a9"
                          />
                        </svg>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <div className={signinsignupstyles["iconDivLock"]}>
                          {showSignupPassword ? (
                            <BiHide
                              onClick={handleShowSignupPassword}
                              className={signinsignupstyles["eye-icon"]}
                            />
                          ) : (
                            <IoMdEye
                              onClick={handleShowSignupPassword}
                              className={signinsignupstyles["eye-icon"]}
                            />
                          )}
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
                {errors.password && (
                  <span className={signinsignupstyles.error}>
                    {errors.password}
                  </span>
                )}
              </div>
              <div>
                <CssTextField
                  type={showSignupConfirmPassword ? "text" : "password"}
                  placeholder="Password"
                  onChange={handleInputChange}
                  required
                  name="confirmPassword"
                  fullWidth
                  autoComplete="new-password"
                  variant="outlined"
                  // error={loginError}
                  // helperText={loginError && loginError}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M15.1721 6.52854V3.66854C15.1726 2.92138 14.869 2.20618 14.3313 1.68746C13.7935 1.16873 13.0679 0.891143 12.3212 0.918538H9.31456C8.5679 0.891143 7.84224 1.16873 7.3045 1.68746C6.76675 2.20618 6.46322 2.92138 6.46372 3.66854V6.52854C4.56731 7.20009 3.41384 9.12254 3.71372 11.1119L4.58456 16.401C5.00316 18.5274 6.89125 20.0441 9.05789 19.9944H12.6146C14.7812 20.0441 16.6693 18.5274 17.0879 16.401L17.9587 11.1119C18.2541 9.1125 17.0831 7.18641 15.1721 6.52854ZM11.7346 13.6877L11.9271 14.256C12.0115 14.5162 11.9674 14.8009 11.8082 15.0233C11.6491 15.2458 11.3938 15.3794 11.1204 15.3835H10.4421C10.1686 15.3794 9.91334 15.2458 9.75421 15.0233C9.59508 14.8009 9.55099 14.5162 9.63539 14.256L9.82789 13.6877C9.93168 13.3666 9.85069 13.0145 9.61706 12.771C9.09762 12.1539 9.14999 11.2387 9.73643 10.6849C10.3229 10.131 11.2396 10.131 11.826 10.6849C12.4125 11.2387 12.4648 12.1539 11.9454 12.771C11.7118 13.0145 11.6308 13.3666 11.7346 13.6877ZM7.83872 6.21687H8.16872V6.22604H13.7971V3.66854C13.7624 2.8872 13.1027 2.28083 12.3212 2.31187H9.31456C8.53307 2.28083 7.87343 2.8872 7.83872 3.66854V6.21687Z"
                            fill="#70a3a9"
                          />
                        </svg>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <div className={signinsignupstyles["iconDivLock"]}>
                          {showSignupConfirmPassword ? (
                            <BiHide
                              onClick={handleShowSingupConfirmPassword}
                              className={signinsignupstyles["eye-icon"]}
                            />
                          ) : (
                            <IoMdEye
                              onClick={handleShowSingupConfirmPassword}
                              className={signinsignupstyles["eye-icon"]}
                            />
                          )}
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />

                {errors.confirmPassword && (
                  <span className={signinsignupstyles.error}>
                    {errors.confirmPassword}
                  </span>
                )}
              </div>

              <div className={signinsignupstyles.btn}>
                <button className={signinsignupstyles.styledButton}>
                  <div className={signinsignupstyles.innerButton}>Sign Up</div>
                </button>
              </div>
            </form>
          )}
        </div>
        <div className={signinsignupstyles.bottom}>
          <div className={signinsignupstyles.Footer}>
            <Footer />
          </div>
        </div>
        {forgotPasswordClicked && (
          <ResetPassword
            setForgotPasswordClicked={setForgotPasswordClicked}
            setPassupsuccesfully={setForgotPasswordClicked}
          />
        )}
        {showotpmodel && (
          <OTPConfirmationModel
            username={formData.email}
            password={formData.password}
            setOtpmodel={setOtpmodel}
          />
        )}
      </div>
    </>
  );
};

export default SigninSignup;
