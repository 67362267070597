import React, { createContext, useState, useContext, useEffect } from 'react';

// Initialize the context
const OrderContext = createContext();

// Define the provider component
export const OrderProvider = ({ children }) => {
  const [orderData, setOrderData] = useState(() => {
    const savedOrderData = localStorage.getItem('orderData');
    return savedOrderData ? JSON.parse(savedOrderData) : {
      "order-details": {
        "order-id": ""
      },
      "book-details": []
    };
  });

  // Save orderData to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem('orderData', JSON.stringify(orderData));
  }, [orderData]);

  const addBookDetail = (newBookDetail) => {
    setOrderData(prevState => ({
      ...prevState,
      "book-details": [...prevState["book-details"], newBookDetail]
    }));
  };

  const resetOrderData = () => {
    const initialOrderData = {
      "order-details": {
        "order-id": ""
      },
      "book-details": []
    };
    setOrderData(initialOrderData);
  };

  const updateOrderId = (newOrderId) => {
    setOrderData(prevState => ({
      ...prevState,
      "order-details": {
        ...prevState["order-details"],
        "order-id": newOrderId
      }
    }));
  };

  const removeBookDetailById = (tempBookID) => {
    setOrderData(prevState => ({
      ...prevState,
      "book-details": prevState["book-details"].filter(book => book["bookTempID"] !== tempBookID)
    }));
  };

  const updateBookQuantityById = (tempBookID, newQuantity) => {
    setOrderData(prevState => ({
      ...prevState,
      "book-details": prevState["book-details"].map(book =>
        book["bookTempID"] === tempBookID
          ? { ...book, quantity: newQuantity }
          : book
      )
    }));
  };

  const incrementBookQuantityById = (tempBookID) => {
    setOrderData(prevState => ({
      ...prevState,
      "book-details": prevState["book-details"].map(book =>
        book["bookTempID"] === tempBookID
          ? { ...book, quantity: book.quantity + 1 }
          : book
      )
    }));
  };

  const decrementBookQuantityById = (tempBookID) => {
    setOrderData(prevState => ({
      ...prevState,
      "book-details": prevState["book-details"].map(book => {
        if (book["bookTempID"] === tempBookID) {
          const newQuantity = book.quantity - 1;
          return newQuantity > 0 ? { ...book, quantity: newQuantity } : null;
        }
        return book;
      }).filter(book => book !== null)
    }));
  };

  return (
    <OrderContext.Provider value={{ orderData, incrementBookQuantityById,setOrderData, addBookDetail, updateOrderId, removeBookDetailById, updateBookQuantityById,decrementBookQuantityById,resetOrderData }}>
      {children}
    </OrderContext.Provider>
  );
};

// Custom hook for using the context
export const useOrder = () => useContext(OrderContext);
