import React, { useState } from 'react'
import newslettertyles from './Newsletter.module.css';
import { TailSpin } from "react-loader-spinner";
import { useDispatch } from 'react-redux';
import { setcolor, setIcon, setmessage, setsize, settoaststate, setuniqueId, setvariant } from '../../../../features/toastSlice';
import { post } from 'aws-amplify/api';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const Newsletter = ({btncolor, newslettercolor}) => {
  const [loading, setloading] = useState(false);
const [newsletteremail, setNewsletteremail] = useState('');
const [error, setError] = useState('');

  const dispatch = useDispatch();

  const handleNewsletter = async () => {

    if (!newsletteremail|| !/\S+@\S+\.\S+/.test(newsletteremail)) {
      
      dispatch(settoaststate(true));
      dispatch(setsize('sm'));
      dispatch(setvariant('soft'));
      dispatch(setcolor('danger'));
      dispatch(setmessage('Invalid email address'));
      dispatch(setIcon('danger'));
      dispatch(setuniqueId(`newsletter`))
      return
    }
    setloading(true)
    try{
      
      const response = await fetch(`${apiBaseUrl}/items/newsletter`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: newsletteremail,
        })
      });


      // const response = post({
      //   apiName: 'apiBooks321',
      //   path: '/items/newsletter',
      //   options: {
      //     headers: {
      //       'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify({
      //         email: newsletteremail,
      //       })
      //   }
      // });
      if(response.status === 200){
        dispatch(settoaststate(true));
        dispatch(setsize('sm'));
        dispatch(setvariant('soft'));
        dispatch(setcolor('success'));
        dispatch(setmessage('Subscription Confirmation'));
        dispatch(setIcon('success'));
        dispatch(setuniqueId(`successnewsletter`))
      }
      setNewsletteremail('')
      setloading(false)
    }catch(error){
      console.error("error",error)
      setloading(false)
    }
  }

  const handleInputChange = (e) => {
    setError("")
    setNewsletteremail(e.target.value);
  }

  return (
    <div className={newslettertyles.main}>
        <h1 style={{color: newslettercolor}} className={newslettertyles.heading}>news letter</h1>
        <input placeholder='Enter your email address' className={newslettertyles.input}
        onChange={handleInputChange} type="email" value={newsletteremail} id="email" name="email"
        />
        <div onClick={handleNewsletter} style={{backgroundColor:btncolor}} className={newslettertyles.signup}>
            {loading ? (
                  <TailSpin
                    height="20"
                    width="20"
                    color="#fff"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    visible={true}
                  />
                ) : (
                  "Sign up for news letter"
                )}
        </div>
    </div>
  )
}

export default Newsletter