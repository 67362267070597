import React, { useEffect, useState } from 'react'
import Homestyles from './Home.module.css'
import mainpageCharacters from '../../Assets/Images/Home/mainpageCharacters.webp'
import '../../fonts.css';
import aboutuscharacter from '../../Assets/Images/Home/AboutusCharacter.webp'
// import CreateYourOwnBook from '../../Assets/SVG/CreateYourOwnBook.svg';
import { ReactComponent as CreateYourOwnBook } from '../../Assets/SVG/CreateYourOwnBook.svg';
import OurBooks from '../../Components/OurBooks/OurBooks';
import AboutusComponent from './Components/AboutusComponent/AboutusComponent';
import OurBestSellers from './Components/OurBestSellers/OurBestSellers';
import ReviewsHomeComponent from './Components/ReviewsHomeComponent/ReviewsHomeComponent';
import ContactusHome from './Components/HomeContactus/ContactusHome';
import Footer from '../../Components/Footer/Footer';
import { getallbooks } from '../API/API';
import { useNavigate } from 'react-router-dom';
import CreateBookSvg from '../../SvgIcons/CreateBookBtn/CreateBookSvg';
import { FaChevronRight } from "react-icons/fa";
import HomeOurBooks from './Components/HomeOurBooks/HomeOurBooks';
import { useDispatch, useSelector } from 'react-redux';

import { get } from 'aws-amplify/api';
import { useApi } from '../../context/ApiContext';
import BookLoader from '../../Components/BookLoader/BookLoader';
import CustomButton from '../../Components/CustomButton/CustomButton';
import { IoIosArrowForward } from 'react-icons/io';
import { TailSpin } from 'react-loader-spinner';

const Home = () => {
  const navigate = useNavigate();
  
  // const [booksData, setbooksData] = useState(null);

  // useEffect(()=>{
  //   const GetallbookDetails = async () => {
  //   try {
  //     const response = await getallbooks();
  //     console.log("response",response)
  //     if(response){
  //       setbooksData(response)
  //     }
  //   } catch (err) {
  //     console.error("Login error:", err);
  //   }
  // };
  // GetallbookDetails()
  // },[booksData])

//  let loading = true;
//   const dispatch = useDispatch();
// const { books, otherData, loading, error } = useApi();

//   useEffect(() => {
//     if (status === 'idle') {
      
//       callfun();
//     }
//   }, [status, dispatch]);
  
//   const callfun = async () => {
//     try {
//       const restOperation = get({
//         apiName: 'apiBooks321',
//         path: '/items/books',
//       });
  
//       const response = await restOperation.response;
// console.log("response.statusCode",response.statusCode)
//       if (response.statusCode === 200) {
//       const data = await response.body.json();
//       console.log("data", data);
//       }
      
//     } catch (error) {
//       console.error("Error fetching books:", error);
//       throw error;
//     }
//   };
// let loading = true;
  return (
    <>
   <div className={Homestyles.HomeContainer}>
        <div className={Homestyles.mainpage}>
          <div className={Homestyles.mainpageLeft}>
            {/* <h1 className={Homestyles.heading}>Personalised<br /><span>Children<span className={Homestyles.apostrophe}>'</span>s</span><br />Story Books</h1> */}
            <h1 className={Homestyles.heading}>Personalised Children<span className={Homestyles.apostrophe}>'</span>s Story Books</h1>

            <div className={Homestyles.CreateYourOwnBook} >
              {/* <CreateBookSvg /> */}
              <CustomButton incoBg={true} icon={<IoIosArrowForward style={{width:"40px", height:"40px"}} />} outerDivBg={"#BB82F9"} innerDivBg={"#AA76E2"} text={"Create Your Book"} fontSize={"22px"} onClick={()=> navigate('/our-books')} width={"280px"} height={"80px"} topGradient={"rgb(156 102 212)"} bottomGradient={"rgb(189 158 220)"} />
            </div>
          </div>
          <div className={Homestyles.mainpageRight}>
            <img src={mainpageCharacters} alt='mainpageCharacters' />
          </div>
        </div>
        <div className={Homestyles.OurBooks}>
          {/* <OurBooks titleColor={"#FFFFFF"}/> */}
          <HomeOurBooks titleColor={"#FFFFFF"}/>
        </div>
        <div className={Homestyles.Aboutus}>
          <AboutusComponent />
          <img src={aboutuscharacter} alt='aboutuscharacter' className={Homestyles.aboutuscharacter} />
        </div>
        <div className={Homestyles.ourbestsellers}>
          <OurBestSellers titleColor={'#FFFFFF'} subheading={'#452565'} />
          <div className={Homestyles.viewallbooks} onClick={() => navigate('/our-books')}>
            See All <FaChevronRight  style={{fontSize:'12px'}}/>
        </div>
        </div>
        <div className={Homestyles.reviews}>
          <ReviewsHomeComponent />
        </div>
        <div className={Homestyles.HomeContactus}>
          <ContactusHome/>
        </div>
        <div className={Homestyles.homeFooter}>
            <Footer />
          </div>
      </div>
    </>
  )
}

export default Home