import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { settoaststate } from '../../features/toastSlice';
import { Button, Snackbar } from '@mui/joy';
import PlaylistAddCheckCircleRoundedIcon from '@mui/icons-material/PlaylistAddCheckCircleRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';

export const MySnackbar = ({ variant, color, toaststate, message, size,iCon, uniqueKey }) => {
  const dispatch = useDispatch();

  const handleClose = () => dispatch(settoaststate(false));

  useEffect(() => {
    if (toaststate) {
      const timer = setTimeout(() => {
        dispatch(settoaststate(false));
      }, 3000); // autoHideDuration

      return () => clearTimeout(timer); // Cleanup timeout on component unmount
    }
  }, [toaststate, dispatch]);

  const getIconByVariant = (iCon) => {
    switch (iCon) {
      case 'success':
        return <PlaylistAddCheckCircleRoundedIcon />;
      case 'error':
        return <ErrorOutlineRoundedIcon />;
      case 'info':
        return <InfoRoundedIcon />;
      case 'warning':
        return <WarningAmberRoundedIcon />;
      default:
        return <InfoRoundedIcon />; // Default icon if no match
    }
  };

  return (
    <Snackbar
      key={uniqueKey}
      variant={variant}
      color={color}
      open={toaststate}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      startDecorator={getIconByVariant(iCon)}
      autoHideDuration={3000}
      endDecorator={
        <Button
          onClick={() => dispatch(settoaststate(false))}
          size={size}
          variant={variant}
          color={color}
        >
          Dismiss
        </Button>
      }
      sx={{ zIndex: 100000000000000 }}
    >
      {message}
    </Snackbar>
  );
};
