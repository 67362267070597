import OurBooksStyles from './OurBooks.module.css'
import BookCard from '../BookCard/BookCard';
import book1Image from '../../Assets/BookCovers/Book1.webp';
import { useState } from 'react';


import book2Image from '../../Assets/BookCovers/docterboy.webp';
import book3Image from '../../Assets/BookCovers/firefightergirl.webp';
import book4Image from '../../Assets/BookCovers/footballergirl.webp';
import book5Image from '../../Assets/BookCovers/youtubegirl.webp';
import book6Image from '../../Assets/BookCovers/vetboycover.webp';

import book7Image from '../../Assets/BookCovers/teacherboy.webp';
import book8Image from '../../Assets/BookCovers/drivergirl.webp';
import book9Image from '../../Assets/BookCovers/policeboy.webp';
import book10Image from '../../Assets/BookCovers/nursegirl.webp';


const booksData = [
  { bookId: 'astronaut', title: 'I Want To Be An Astronaut', rating: 5, category: 'astronaut', price: 23.99, imageUrl: book1Image },
  { bookId: 'doctor', title: 'I Want To Be A Doctor', rating: 5, category: 'doctor', price: 23.99, imageUrl: book2Image },
  { bookId: 'firefighter', title: 'I Want To Be A Firefighter',rating: 5, category: 'firefighter', price: 23.99, imageUrl: book3Image },
  { bookId: 'footballer', title: 'I Want To Be A Footballer', rating: 5, category: 'footballer', price: 23.99, imageUrl: book4Image },
  { bookId: 'youtuber', title: 'I Want To Be A Youtuber', rating: 5, category: 'youtuber', price: 23.99, imageUrl: book5Image },
  { bookId: 'nurse', title: 'I Want To Be A Nurse', rating: 5, category: 'nurse', price: 23.99, imageUrl: book10Image },
  { bookId: 'teacher', title: 'I Want To Be A Teacher', rating: 5, category: '', price: 23.99, imageUrl: book7Image },
  { bookId: 'traindriver', title: 'I Want To Be A Train Driver', rating: 5, category: '', price: 23.99, imageUrl: book8Image },
  { bookId: 'policeofficer', title: 'I Want To Be A Police Officer', rating: 5, category: '', price: 23.99, imageUrl: book9Image },
  { bookId: 'vet', title: 'I Want To Be A Vet', rating: 5, category: '', price: 23.99, imageUrl: book6Image },

  // { bookId: 11, title: 'I Want To Be A Teacher', rating: 5, category: '', price: 23.99, imageUrl: book7Image },
  // { bookId: 12, title: 'I Want To Be A Train Driver', rating: 5, category: '', price: 23.99, imageUrl: book8Image },
  // { bookId: 13, title: 'I Want To Be A Police Officer', rating: 5, category: '', price: 23.99, imageUrl: book9Image },
  // { bookId: 14, title: 'I Want To Be A Vet', rating: 5, category: '', price: 23.99, imageUrl: book6Image },
  // Add more books as needed
];

const OurBooks = ({titleColor}) => {
  // const [bookDetail, setbookDetail] = useState([booksData]);
  // console.log("booksData",booksData.imageUrl)
  const [value, setValue] = useState();

  return (
    <>
      {booksData &&
        <div className={OurBooksStyles.main}>
          <h1 style={{color: titleColor}} className={OurBooksStyles.mainheading}>Our Books</h1>

          <div className={OurBooksStyles.bookContainer}>
            {booksData.map((book) => (
              <div key={book.bookId}>
              <BookCard
                product={book}
              />
              </div>
            ))}
          </div>
        </div>
      }
    </>
  );
};

export default OurBooks;
