import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './store';
import { Provider } from "react-redux"
import { BrowserRouter as Router } from 'react-router-dom';

import { ApiProvider } from './context/ApiContext';

import { Amplify } from 'aws-amplify';
import config from './amplifyconfiguration.json';
import { UserProvider } from './context/UserContext';
import { OrderProvider } from './context/OrderContext';

Amplify.configure(config);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <Provider store={store}>
    {/* <ApiProvider> */}
    <Router>
      <UserProvider>
      <OrderProvider>

        <App />
      </OrderProvider>
      </UserProvider>
    </Router>
    {/* </ApiProvider> */}
  </Provider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
