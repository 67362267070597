import React, { useEffect, useState } from 'react'
import ourStory from './ourStory.module.css'
import '../../fonts.css';
import Footer from '../../Components/Footer/Footer';

// import ourstoryboard from '../../Assets/Backgrounds/OurStory/ourstoryboard.webp';
import ourstoryboard from '../../Assets/Backgrounds/OurStory/Bord2.webp';

import astronaut from "../../Assets/Images/siteImages/Astronauts.webp"
import owl from "../../Assets/Images/siteImages/flyingOwl.webp"

import ball from "../../Assets/Images/siteImages/ball.webp"
import fox from "../../Assets/Images/siteImages/fox.webp"

const OurStory = () => {

    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 768);

  const handleResize = () => {
    setIsLargeScreen(window.innerWidth >= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

    return (
        <div className={ourStory.PrivacyPolicy}>
            <div className={ourStory.mainContainer}>
                <div className={ourStory.header_container}>
                    <h1 className={ourStory.heading}>I Want to Be</h1>
                </div>
                <p className={ourStory.description}>
                    {/* We make people feel incredibly special with the
                    <br/>world’s best personalized books */}
                    Sparking children's aspirations through personalised story books.<br/>
                    <span style={{fontSize: '28px', fontStyle: 'italic'}}>Dream, Read, Become.</span>
                </p>
                   {!isLargeScreen ? (
                 <>
                    <div className={ourStory.iconandcontent}>
                    <div className={ourStory.owlImgContainer}>
                        <img src={owl} alt="" className={ourStory.imgowl} />
                    </div>
                    <div className={ourStory.foxImgContainer}>
                        <img src={fox} alt="" className={ourStory.imgfox} />
                    </div>
                <div className={ourStory.content}>
                    <ul className={ourStory.uolist}>
                        <li>
                            The inspiration for these books came from the request of one of our children who wanted a bedtime story about growing up to be an astronaut. 
                        </li>
                    </ul>
                    <ul className={ourStory.uolist}>
                        <li>
                            We realised that even young children have aspirations too. From there, a new, magical bedtime story idea was born and along came the I Want to Be series!
                        </li>
                    </ul>
                    <ul className={ourStory.uolist}>
                        <li>
                            At I Want to Be, our mission is to ignite the imaginations of children and inspire their dreams through personalised stories that reflect their unique aspirations. We create a world where every child can see themselves as the hero of their own adventure, paving the way to their dream careers.
                            <br/>
                        </li>
                    </ul>
                    <div className={ourStory.taglinecontainer}>
                    <span className={ourStory.tagline}>

                        <span className={ourStory.quote}>"</span> <span style={{color: '#2DC7FB'}}>Dream</span>
                            <span className={ourStory.dot}>.</span><span style={{color: '#452565'}}>Read</span><span className={ourStory.dot}>.</span><span style={{color: '#CE2665'}}>Become</span><span className={ourStory.quote}>."</span>
                        </span>
                    </div>
                </div>                
        <div className={ourStory.astronautImgContainer}>
                            <img src={astronaut} alt="" className={ourStory.imgast} />
                </div>
                <div className={ourStory.ballImgContainer}>
                    <img src={ball} alt="" className={ourStory.imgball} />
                </div>
                </div>
                </>)
                    :
                <div className={ourStory.newourstorycontent}>
                    <img src={ourstoryboard} alt='ourstoryboard' className={ourStory.boardimg}/>
                </div>
                   }

                <div className={ourStory.HomeContactus}>

                    <div className={ourStory.Footer}>
                        <Footer />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurStory