import React, { useEffect, useState } from 'react'
import Homestyles from './profile.module.css'
import mainpageCharacters from '../../Assets/Images/Home/mainpageCharacters.webp'
import '../../fonts.css';
import aboutuscharacter from '../../Assets/Images/Home/AboutusCharacter.webp'
// import CreateYourOwnBook from '../../Assets/SVG/CreateYourOwnBook.svg';
import { ReactComponent as CreateYourOwnBook } from '../../Assets/SVG/CreateYourOwnBook.svg';

import AboutusComponent from '../Home/Components/AboutusComponent/AboutusComponent';
import OurBestSellers from '../Home/Components/OurBestSellers/OurBestSellers';
import ReviewsHomeComponent from '../Home/Components/ReviewsHomeComponent/ReviewsHomeComponent';
import ContactusHome from '../Home/Components/HomeContactus/ContactusHome';
import Footer from '../../Components/Footer/Footer';
import { getallbooks } from '../API/API';
import OurBooks from '../../Components/OurBooks/OurBooks';
import ProfileTop from './Components/ProfileTop/ProfileTop';

const Profile = ({fullName}) => {

  return (
    <>
      <div className={Homestyles.HomeContainer}>
        {/* <div className={Homestyles.OurBooks}> */}
          <ProfileTop fullName={fullName} />
        {/* </div> */}
        {/* <div className={Homestyles.Profilefooterbanner}>
          <div className={Homestyles.Aboutus}>
          </div>
        </div> */}
        <div className={Homestyles.HomeContactus}>
          <div className={Homestyles.Footer}>
            <Footer />
          </div>
        </div>
      </div>
    </>
  )
}

export default Profile