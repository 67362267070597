import React, { useState } from "react";
import Bookcategoriesstyles from "./Bookcategories.module.css";
import "../../fonts.css";
import { ReactComponent as AddtoCartBook } from "../../Assets/SVG/AddtoCartBook.svg";
import { ReactComponent as Thumbsup } from "../../Assets/SVG/Thumbsup.svg";
import { ReactComponent as Dilivered } from "../../Assets/SVG/Dilivered.svg";
import Reviews from "./Component/Reviews/Reviews";
import Newsletter from "./Component/Newsletter/Newsletter";
import OurBestSellers from "../Home/Components/OurBestSellers/OurBestSellers";
import Footer from "../../Components/Footer/Footer";
import { useNavigate, useParams } from "react-router-dom";
import { GrFormPrevious } from "react-icons/gr";
import { GrFormNext } from "react-icons/gr";
import { FaChevronRight } from "react-icons/fa";
import { FaRegThumbsUp } from "react-icons/fa";
import { TbTruckDelivery } from "react-icons/tb";
import { FaShoppingCart } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../features/cart/cartSlice";

//astranaut
import book from "../../Assets/Images/Bookcategories/Astronaut/book.webp";
import doorbean from '../../Assets/Images/Bookcategories/Astronaut/doorbean.webp'
import astronaut from "../../Assets/Images/Bookcategories/Astronaut/astronautcharacter.webp";
import reviewsbgimg from '../../Assets/Images/Bookcategories/Astronaut/ourbestseller.webp';
// import reviewsbgimg from '../../Assets/Images/Bookcategories/Astronaut/abc.png';
import newsletterbgimg from '../../Assets/Images/Bookcategories/Astronaut/Home.webp';
import Astronautsfyling from '../../Assets/Images/Bookcategories/Astronaut/Astronautsfyling.webp';
import hillsbg from '../../Assets/Images/Bookcategories/Astronaut/natutehill.webp';

//firefighter
import firefighterbook from '../../Assets/Images/Bookcategories/Firefighter/fighterbook.webp';
import firefighterreviewsbgimg from '../../Assets/Images/Bookcategories/Firefighter/fighterreviews.webp';
import firefighternewsletterbgimg from '../../Assets/Images/Bookcategories/Firefighter/newsletterfighter.webp';
import fox from '../../Assets/Images/Bookcategories/Firefighter/fox.webp';
import firefighterfooterbg from '../../Assets/Images/Bookcategories/Firefighter/fighterfooterbg.webp';
import firefightercharacter from '../../Assets/Images/Bookcategories/Firefighter/firefightercharacter.webp';
import flyingbat from '../../Assets/Images/Bookcategories/Firefighter/flyingbat.webp';


import firefighterimage1 from '../../Assets/Images/Bookcategories/Firefighter/1.webp'
import firefighterimage2 from '../../Assets/Images/Bookcategories/Firefighter/2.webp'
import firefighterimage3 from '../../Assets/Images/Bookcategories/Firefighter/3.webp'
import firefighterimage4 from '../../Assets/Images/Bookcategories/Firefighter/4.webp'

// footballer
import footballerBook from '../../Assets/Images/Bookcategories/Footballer/footballerBookImg.webp';
import footballerCharacter from '../../Assets/Images/Bookcategories/Footballer/footballerCharacter.webp';
import footballerReviewBg from '../../Assets/Images/Bookcategories/Footballer/footballerReviewBg.webp';
import ball from '../../Assets/Images/Bookcategories/Footballer/ball.webp';
import footballerNewsletter from '../../Assets/Images/Bookcategories/Footballer/footballerNewsletter.webp';
import squirrel from '../../Assets/Images/Bookcategories/Footballer/squirrel.webp';
import footballerFooterImg from '../../Assets/Images/Bookcategories/Footballer/footballerFooterImg.webp';
import footballerimage1 from '../../Assets/Images/Bookcategories/Footballer/1.webp'
import footballerimage2 from '../../Assets/Images/Bookcategories/Footballer/2.webp'
import footballerimage3 from '../../Assets/Images/Bookcategories/Footballer/3.webp'
import footballerimage4 from '../../Assets/Images/Bookcategories/Footballer/4.webp'

// youtuber
import youtuberBook from '../../Assets/Images/Bookcategories/Youtuber/youtuberBook.webp';
import youtuberCharacter from '../../Assets/Images/Bookcategories/Youtuber/youtubeCharacter.webp';
import youtuberReviewBg from '../../Assets/Images/Bookcategories/Youtuber/reviewBg.webp';
import house from '../../Assets/Images/Bookcategories/Youtuber/house.webp';
import youtuberNewsletterBg from '../../Assets/Images/Bookcategories/Youtuber/newsletterBg.webp';
import youtubeIcon from '../../Assets/Images/Bookcategories/Youtuber/youtubeIcon.webp';
import youtubeFooterBg from '../../Assets/Images/Bookcategories/Youtuber/footerBg.webp';

import youtuberimage1 from '../../Assets/Images/Bookcategories/Youtuber/1.webp'
import youtuberimage2 from '../../Assets/Images/Bookcategories/Youtuber/2.webp'
import youtuberimage3 from '../../Assets/Images/Bookcategories/Youtuber/3.webp'
import youtuberimage4 from '../../Assets/Images/Bookcategories/Youtuber/4.webp'

//doctor
import doctorBook from '../../Assets/Images/Bookcategories/Doctor/d1.webp';
import doctorCharacter from '../../Assets/Images/Bookcategories/Doctor/doctorCharacter.webp';
import doctorReviewBg from '../../Assets/Images/Bookcategories/Doctor/reviewsBg.webp';
import doctorNewsletterBg from '../../Assets/Images/Bookcategories/Doctor/newsletterBg.webp';
import doctorFooterBg from '../../Assets/Images/Bookcategories/Doctor/footerBg.webp';
import DoctorThumbUp from "../../Assets/Images/Bookcategories/Doctor/Icons/ThumbUp"
import DoctorVan from "../../Assets/Images/Bookcategories/Doctor/Icons/Van"

import bottle from "../../Assets/Images/Bookcategories/Doctor/bottle.webp";
import table from "../../Assets/Images/Bookcategories/Doctor/table.webp";

import doctorimage1 from '../../Assets/Images/Bookcategories/Doctor/1.jpg'
import doctorimage2 from '../../Assets/Images/Bookcategories/Doctor/2.jpg'
import doctorimage3 from '../../Assets/Images/Bookcategories/Doctor/3.jpg'


//nurse
import nursereview from '../../Assets/Images/Bookcategories/nurse/nursereviewsbg.webp'
import nursecharacter from '../../Assets/Images/Bookcategories/nurse/nursecharacter.webp'
import nursefooter from '../../Assets/Images/Bookcategories/nurse/nursefooter.webp'
import nursecover from '../../Assets/Images/Bookcategories/nurse/nursecover.webp'
import nurseobject1 from '../../Assets/Images/Bookcategories/nurse/nurseimg1.webp'
import nurseobject2 from '../../Assets/Images/Bookcategories/nurse/nurseimg2.webp'
import nursenewsletter from '../../Assets/Images/Bookcategories/nurse/newletter.webp'

import nurseimage1 from '../../Assets/Images/Bookcategories/nurse/1.webp'
import nurseimage2 from '../../Assets/Images/Bookcategories/nurse/2.webp'
import nurseimage3 from '../../Assets/Images/Bookcategories/nurse/3.webp'

//cover
import astranautimg1 from '../../Assets/Images/Bookcategories/Astronaut/1.webp';
import astranaut1 from '../../Assets/BookCovers/astranaut1.webp';
import astranaut2 from '../../Assets/BookCovers/astranaut2.webp';
import astranaut3 from '../../Assets/BookCovers/astranaut3.webp';
import AddToCartBtnAstronaut from "../../Assets/Images/Bookcategories/Astronaut/AddToCartBtnAstronaut";
import AddToCartBtnFootballer from "../../Assets/Images/Bookcategories/Footballer/AddToCartBtnFootballer";
import AddToCartBtnYoutuber from "../../Assets/Images/Bookcategories/Youtuber/AddToCartBtnYoutuber";
import AddToCartBtnDoctor from "../../Assets/Images/Bookcategories/Doctor/AddToCartBtnDoctor";
import AddToCartBtnfirefighter from "../../Assets/Images/Bookcategories/Firefighter/AddToCartBtnFirefighter";
import { IoIosArrowForward } from "react-icons/io";
import CustomButton from "../../Components/CustomButton/CustomButton";
import { setBookdata } from "../../features/characterSlice";
import ZoomLens from "./ZoomLens/ZoomLens";

import book2Image from '../../Assets/BookCovers/docterboy.webp';
import book3Image from '../../Assets/BookCovers/firefightergirl.webp';
import book4Image from '../../Assets/BookCovers/footballergirl.webp';
import book5Image from '../../Assets/BookCovers/youtubegirl.webp';
import book6Image from '../../Assets/BookCovers/vetboycover.webp';

import book7Image from '../../Assets/BookCovers/teacherboy.webp';
import book8Image from '../../Assets/BookCovers/drivergirl.webp';
import book9Image from '../../Assets/BookCovers/policeboy.webp';
import book10Image from '../../Assets/BookCovers/nursegirl.webp';
import { setcolor, setIcon, setmessage, setsize, settoaststate, setuniqueId, setvariant } from "../../features/toastSlice";
// import CustomAlert from "../../Components/CustomAlert/CustomAlert";

const Bookcategories = () => {
  const { category } = useParams();

  const navigate = useNavigate();

  const categoryDetails = {
    astronaut: {
      bookId: "astronaut",
      title: "I Want To Be An Astronaut",
      titlecolor: '#452565',
      btncolor: '#452565',
      reviewscolor: '#fff',
      newslettercolor: '#fff',
      image: book,
      imageUrl: book,
      image1: astranaut1,
      image2: astranaut2,
      image3: astranaut3,
      character: astronaut,
      cartBtn: <AddToCartBtnAstronaut />,
      thumb: '#452565',
      van: '#452565',
      btnouterDivBg: "#BB82F9",
      btninnerDivBg: "#AA76E2",
      images: [astranautimg1, astranaut1, astranaut2, astranaut3],
      price: '23.99',
      paragraph: 'This is a story about a child who dreamed of exploring space. With their imagination, they transformed everyday moments into cosmic adventures. From helping neighbours to playing with friends, their ideas took flight as the dream of becoming a real astronaut held endless possibilities',
      reviewsbg: `url(${reviewsbgimg})`,
      reviewsimage: doorbean,
      rating: 5,
      reviewsimageposition: {
        right: '-5%',
        top: '25%'
      },
      newletterbg: `url(${newsletterbgimg})`,
      newsletterCharacter: Astronautsfyling,
      ourbestsellerTitlecolor: "#452565",
      footerbg: `url(${hillsbg})`,
      CustomersReviews: [
        {
          name: 'Jocelyne B., London',
          review: "This delightful astronaut book captivated my child's imagination from the very first page! The vibrant illustrations are amazing. He wants this read to him every night.",
          start: 5
        },
        {
          name: 'Peter Harris, Stockport',
          review: "What a wonderful find! This book strikes the perfect balance between entertainment and education and offered a charming storyline that kept both my children engaged, not least as they were the main character.",
          start: 5
        },
        {
          name: 'Sally, Tyne-and-Wear',
          review: "The book’s illustrations are beautifully done, and the story is both engaging and inspiring. My 6-year-old loved the fact it was about her.",
          start: 5
        },
        {
          name: 'Darren W, Essex',
          review: "The illustrations are colourful and detailed, keeping my 4-year-old's attention throughout. It's a wonderful way to encourage a love of from a young age. Definitely a favourite in our household!",
          start: 5
        }
      ]
    },
    firefighter: {
      bookId: "firefighter",
      title: "I Want To Be A Firefighter",
      titlecolor: '#955002',
      btncolor: '#955002',
      reviewscolor: '#fff',
      newslettercolor: '#fff',
      imageUrl: book3Image,
      image: firefighterbook,
      image1: astranaut1,
      image2: astranaut2,
      image3: astranaut3,
      rating: 5,
      cartBtn: <AddToCartBtnfirefighter />,
      thumb: '#955002',
      van: '#955002',
      btnouterDivBg: "#FACF7C",
      btninnerDivBg: "#FFA902",
      images: [firefighterimage1, firefighterimage2, firefighterimage3, firefighterimage4],
      character: firefightercharacter,
      price: '23.99',
      paragraph: 'This is a story about a child who dreamed of being a magical firefighter. With the help of a wish-granting dragon named Spark, they transformed into a heroic firefighter, saving animals and helping friends. Even without magic, their kind heart made them the true star of this wonderful story.',
      reviewsbg: `url(${firefighterreviewsbgimg})`,
      reviewsimage: fox,
      reviewsimageposition: {
        right: '-6%',
        bottom: 0
      },
      newletterbg: `url(${firefighternewsletterbgimg})`,
      newsletterCharacter: flyingbat,
      ourbestsellerTitlecolor: "#955002",
      footerbg: `url(${firefighterfooterbg})`,
      CustomersReviews: [
        {
          name: 'Fiona Churchbury, Surrey',
          review: "This firefighter book is a fantastic read for young aspiring heroes whilst having a magical element to it that kept my child who is only 4 very engaged from the first page. The illustrations are second to none.",
          start: 5
        },
        {
          name: 'Gareth B, Chelsea',
          review: "My 5-year-old was thrilled with the detailed illustrations and at being the main character. The book also emphasizes the importance of helping others and working together, making it both educational and fun. A great choice for kids interested in heroes and firefighting!",
          start: 5
        },
        {
          name: 'Shakeh A, Sutton',
          review: "A short and sweet story for young minds! This book is filled with action-packed scenes and valuable lessons about courage and community service.",
          start: 5
        },
        {
          name: 'Hamish M, Enfield',
          review: "This book is a fantastic addition to our collection! My 4-year-old was fascinated by the magical dragon and the teamwork the characters had to do. It's a wonderful story with AMAZING pictures.",
          start: 5
        }
      ]
    },
    footballer: {
      bookId: "footballer",
      title: "I Want To Be A Footballer",
      titlecolor: '#24A7C1',
      btncolor: '#24A7C1',
      reviewscolor: '#fff',
      newslettercolor: '#fff',
      image: footballerBook,
      imageUrl: book4Image,
      image1: footballerBook,
      image2: footballerBook,
      image3: footballerBook,
      rating: 5,
      cartBtn: <AddToCartBtnFootballer />,
      thumb: '#24a7c1',
      van: '#24a7c1',
      btnouterDivBg: "#24A7C1",
      btninnerDivBg: "#8CEAFD",
      images: [footballerimage1, footballerimage2, footballerimage3, footballerimage4],
      character: footballerCharacter,
      price: '23.99',
      paragraph: 'This is a story about a child who dreamed of becoming a footballer. With their imagination, they transformed everyday moments into adventures with a magic ball. From practising tricks in an enchanted forest, to performing in a packed stadium with thousands of spectators, the ideas took flight, knowing the future held endless possibilities.',
      reviewsbg: `url(${footballerReviewBg})`,
      reviewsimage: ball,
      reviewsimageposition: {
        right: '-6%',
        bottom: 0
      },
      newletterbg: `url(${footballerNewsletter})`,
      newsletterCharacter: squirrel,

      ourbestsellerTitlecolor: "#24A7C1",
      footerbg: `url(${footballerFooterImg})`,
      CustomersReviews: [
        {
          name: 'Niraj P, Bradford',
          review: "The illustrations are vibrant and bring the tale to life, while the narrative encourages children to dream big and believe in themselves.",
          start: 5
        },
        {
          name: 'Ola, London',
          review: "A delightful story! This book beautifully combines a love for football with a touch of magic, making it perfect for young readers. My 5-year-old was mesmerized by the magical football and his own journey of an aspiring young footballer. The story promotes positive messages about perseverance and following your dreams. A fantastic addition to our collection of sports-themed books!",
          start: 5
        },
        {
          name: 'Michelle Reid, Peterborough',
          review: "This book tells the story of your own child who finds magic in their football, sparking a passion for the game. The engaging storyline and charming illustrations captured my 6-year-old's imagination, and they loved the magical elements woven into the plot.",
          start: 5
        },
        {
          name: 'Andrew Pearce, Dumfries',
          review: "This book is a fantastic read for young football enthusiasts!",
          start: 5
        }
      ]
    },

    youtuber: {
      bookId: "youtuber",
      title: "I Want To Be A Youtuber",
      titlecolor: '#C11D1E',
      btncolor: '#C11D1E',
      reviewscolor: '#fff',
      newslettercolor: '#fff',
      image: youtuberimage1,
      imageUrl: book5Image,
      image1: youtuberimage2,
      image2: youtuberimage3,
      image3: youtuberimage4,
      rating: 5,
      cartBtn: <AddToCartBtnYoutuber />,
      thumb: '#C11D1E',
      van: '#C11D1E',
      btnouterDivBg: "#C11D1E",
      btninnerDivBg: "#FB7C7C",
      images: [youtuberimage1, youtuberimage2, youtuberimage3, youtuberimage4],
      character: youtuberCharacter,
      price: '23.99',
      paragraph: 'This is a story about a child who dreamed of becoming a YouTuber in their spare time. With their imagination, they transformed everyday moments into real, video-making adventures with a bunch of friends. From building castles with blocks, to making robots in class, the ideas took flight, knowing the future held endless possibilities.',
      reviewsbg: `url(${youtuberReviewBg})`,
      reviewsimage: youtubeIcon,
      reviewsimageposition: {
        right: '-6%',
        bottom: 0
      },
      newletterbg: `url(${youtuberNewsletterBg})`,
      newsletterCharacter: house,

      ourbestsellerTitlecolor: "#C11D1E",
      footerbg: `url(${youtubeFooterBg})`,
      CustomersReviews: [
        {
          name: 'Felicity McCalister, Glasgow.',
          review: "What a colourful and modern story! This book takes kids on a journey through the exciting world of being a YouTuber, from coming up with ideas to sharing them online. I also loved the fact it promotes hard work at school and its careful to say that the character wants to do it in his spare time.",
          start: 5
        },
        {
          name: 'Roxanne Delaney, Cork.',
          review: "The colourful illustrations and easy narrative make it perfect for young readers. My child loved learning about the creative process and the importance of being true to oneself, and being the star as well.",
          start: 5
        },
        {
          name: 'Nihir, Leicester.',
          review: "This story about a child dreaming of becoming a YouTuber is both entertaining and insightful. My 6-year-old was fascinated by the process of making videos and sharing them with an audience. The book does a great job of balancing the fun aspects of being online with the promotion of working hard at school. The illustrations are WOW!",
          start: 5
        },
        {
          name: 'Kat S, Herts.',
          review: "We loved this! The story follows a child who dreams of becoming a YouTuber, showcasing the creative and fun side of making videos. The illustrations are bright and engaging, keeping my 5-year-old's attention throughout.",
          start: 5
        }
      ]
    },

    // add other categories here
    doctor:
    {
      bookId: "doctor",
      title: "I Want To Be A Doctor",
      titlecolor: '#221E6B',
      btncolor: '#221E6B',
      newslettercolor: '#fff',
      cartBtn: <AddToCartBtnDoctor />,
      thumb: '#221E6B',
      van: '#221E6B',
      reviewscolor: '#fff',
      btnouterDivBg: "#282475",
      btninnerDivBg: "#716AE6",
      // imageUrl: doctorBook,
      imageUrl: book2Image,
      rating: 5,
      images: [doctorBook, doctorimage1, doctorimage2, doctorimage3],
      character: doctorCharacter,
      price: '23.99',
      paragraph: 'This is a story about a child who dreamed of becoming a Doctor. With their imagination, they transformed everyday moments into adventures with toys and imagination! From practising on their cuddly toys, to going to see real Doctors in action at the hospital, working really hard at school and being kind to others, their ideas took flight, knowing the future held endless possibilities…',
      reviewsbg: `url(${doctorReviewBg})`,
      reviewsimageposition: {
        right: '-6%',
        bottom: 0
      },
      reviewsimage: bottle,
      newletterbg: `url(${doctorNewsletterBg})`,
      newsletterCharacter: table,
      ourbestsellerTitlecolor: "#221E6B",
      footerbg: `url(${doctorFooterBg})`,
      CustomersReviews: [
        {
          name: 'Debbie Smith, Northampton',
          review: "This book is perfect for young readers interested in becoming doctors. The illustrations are so engaging, making it easy for my child to follow along and stay interested. It's an excellent way to encourage a love of science and a desire to help others from a young age, plus he is the star of the book! A bedtime fave!",
          start: 5
        },
        {
          name: 'Sue B, Derbyshire',
          review: "My grandchild was fascinated by the caring nature of herself in the book, and the detailed illustrations of medical tools and hospital settings. It's a fantastic read for kids interested in helping others and learning about health. Highly recommend for any little future doctors.",
          start: 5
        },
        {
          name: 'Jordan S, Borehamwood',
          review: "I bought this for my nephew and created the customised version of him being a little doctor. The story is so sweet and the graphics are insane. He wants it read every night according to my sister.",
          start: 5
        },
        {
          name: 'Kevin, Blackburn',
          review: "The book is a great way to introduce kids to the importance of caring for others.",
          start: 5
        }
      ]
    },

    nurse:
    {
      bookId: "nurse",
      title: "I Want To Be A Nurse",
      titlecolor: '#221E6B',
      btncolor: '#221E6B',
      newslettercolor: '#fff',
      cartBtn: <AddToCartBtnDoctor />,
      thumb: '#221E6B',
      van: '#221E6B',
      reviewscolor: '#fff',
      btnouterDivBg: "#282475",
      btninnerDivBg: "#716AE6",
      imageUrl: book10Image,
      rating: 5,
      images: [nursecover, nurseimage1, nurseimage2, nurseimage3],
      character: nursecharacter,
      price: '23.99',
      paragraph: 'This is a story about a child who dreamed of becoming a Nurse. With their imagination, they transformed everyday moments into adventures with toys and imagination! From practising on their cuddly toys, to going to see real Nurses in action at the hospital, working really hard at school and being kind to others, their ideas took flight, knowing the future held endless possibilities…',
      reviewsbg: `url(${nursereview})`,
      reviewsimageposition: {
        right: '-6%',
        bottom: 0
      },
      reviewsimage: nurseobject2,
      newletterbg: `url(${doctorNewsletterBg})`,
      newsletterCharacter: nurseobject1,
      ourbestsellerTitlecolor: "#221E6B",
      footerbg: `url(${nursefooter})`,
      CustomersReviews: [
        {
          name: 'Sheila Moorehouse, Kidderminster',
          review: "My granddaughter loves this book as she is the main character and gets to visit a hospital and be a nurse for a page or two. Great graphics as well – five stars.",
          start: 5
        },
        {
          name: 'Jack R, Essex',
          review: "Great book and pictures, well worth the money",
          start: 5
        },
        {
          name: 'Priya C, Blackpool',
          review: "Unique and clever concept, my youngest who is 5 was in awe of the fact that she was the main character in such a well produced book. Would highly recommend",
          start: 5
        },
        {
          name: 'Mihail Z, London',
          review: "Exceeded my expectations because usually these books are basic when it comes to customisation, but the graphics and illustrations for these books are just off the charts (I have bought 3 already). My kid wants these read every night",
          start: 5
        }
      ]
    }
  };

  // const bookdata = useSelector((state) => state.characterinfo.bookdata);

  const details = categoryDetails[category] || null;
  const [mainImage, setMainImage] = useState(0)

  const nextImage = () => {
    setMainImage((prevIndex) => (prevIndex + 1) % details['images'].length);
  };

  const prevImage = () => {
    setMainImage((prevIndex) => (prevIndex - 1 + details['images'].length) % details['images'].length);
  };

  const dispatch = useDispatch();

  const handleCreateYourBook = () => {

    
    // dispatch(addToCart({
    //   bookId: details.bookId,
    //   title: details.title,
    //   rating: details.rating,
    //   price: details.price,
    //   imageUrl: details.imageUrl,
    // }))


    const uniqueId = `bookpage${details.bookId}`;
   
    const product = {
      bookId: details.bookId,
      title: details.title,
      rating: details.rating,
      price: details.price,
      imageUrl: details.imageUrl,
    }


    navigate('/createyourcharacter', { state: { productToRoute: product, uniqueIdToRoute: uniqueId } });

 



  }

  const handleaddtocartsecond = () => {

    const uniqueId = `bookpage${details.bookId}`;
   
    const product = {
      bookId: details.bookId,
      title: details.title,
      rating: details.rating,
      price: details.price,
      imageUrl: details.imageUrl,
    }


    navigate('/createyourcharacter', { state: { productToRoute: product, uniqueIdToRoute: uniqueId } });





    // dispatch(addToCart({
    //   bookId: details.bookId,
    //   title: details.title,
    //   rating: details.rating,
    //   price: details.price,
    //   imageUrl: details.imageUrl,
    // }))

    // dispatch(settoaststate(true));
    // dispatch(setsize('sm'));
    // dispatch(setvariant('soft'));
    // dispatch(setcolor('success'));
    // dispatch(setmessage('Item Added to you cart'));
    // dispatch(setIcon('success'));
    // dispatch(setuniqueId(`bookpage${details.bookId}`))

  }
  return (
    <div className={Bookcategoriesstyles.BookcategoriesContainer}>
      <div className={Bookcategoriesstyles.bookcontainer}>
        <div className={Bookcategoriesstyles.book}>
          <div className={Bookcategoriesstyles.bookcolumnmain}>
            <div className={Bookcategoriesstyles.bookcolumn}>
              {details['images'].map((img, index) => {

                return <img className={`${mainImage === index ? Bookcategoriesstyles.active : ""} ${Bookcategoriesstyles.thumbnail}`} key={index} src={img} alt="book" onClick={() => setMainImage(index)} />
              })}
            </div>
            <div className={Bookcategoriesstyles.bookmain}>
              <div className={Bookcategoriesstyles.prev_btn}>
                <GrFormPrevious onClick={prevImage} className={Bookcategoriesstyles.carousalBtns} />
              </div>
              {/* <img src={details['images'][mainImage]} alt="book" className={Bookcategoriesstyles.themainimage}/> */}
              <ZoomLens imageUrl={details['images'][mainImage]} />

              <div className={Bookcategoriesstyles.next_btn}>
                <GrFormNext onClick={nextImage} className={Bookcategoriesstyles.carousalBtns} />
              </div>
              <div className={Bookcategoriesstyles.imagedots}>
                <span style={{ background: mainImage === 0 ? details.titlecolor : "" }} className={Bookcategoriesstyles.thedot}></span>
                <span style={{ background: mainImage === 1 ? details.titlecolor : "" }} className={Bookcategoriesstyles.thedot}></span>
                <span style={{ background: mainImage === 2 ? details.titlecolor : "" }} className={Bookcategoriesstyles.thedot}></span>
                <span style={{ background: mainImage === 3 ? details.titlecolor : "" }} className={Bookcategoriesstyles.thedot}></span>
              </div>
            </div>
          </div>
          <div onClick={handleCreateYourBook} style={{ backgroundColor: details.btncolor }} className={Bookcategoriesstyles.bookbottom}>
            Create Your Book
          </div>
        </div>
        <div className={Bookcategoriesstyles.bookdetails}>
          <div className={Bookcategoriesstyles.bookdetailstop}>
            <h4 style={{ color: details.titlecolor }} className={Bookcategoriesstyles.heading}>
              {/* I Want To Be An Astronaut */}
              {details.title}
            </h4>

            <svg
              width="115"
              height="36"
              viewBox="0 0 291 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M211.9 28.2897L223.457 35.2L220.39 22.176L230.6 13.413L217.155 12.2829L211.9 0L206.645 12.2829L193.2 13.413L203.41 22.176L200.344 35.2L211.9 28.2897Z"
                fill="#FFB618"
              />
              <path
                d="M163.5 28.2897L175.056 35.2L171.99 22.176L182.2 13.413L168.754 12.2829L163.5 0L158.245 12.2829L144.799 13.413L155.01 22.176L151.943 35.2L163.5 28.2897Z"
                fill="#FFB618"
              />
              <path
                d="M115.099 28.2897L126.656 35.2L123.589 22.176L133.799 13.413L120.354 12.2829L115.099 0L109.845 12.2829L96.3998 13.413L106.61 22.176L103.543 35.2L115.099 28.2897Z"
                fill="#FFB618"
              />
              <path
                d="M66.7 28.2897L78.2566 35.2L75.1898 22.176L85.4 13.413L71.9547 12.2829L66.7 0L61.4453 12.2829L48 13.413L58.2102 22.176L55.1434 35.2L66.7 28.2897Z"
                fill="#FFB618"
              />
              <path
                d="M18.3 28.2897L29.8566 35.2L26.7898 22.176L37 13.413L23.5547 12.2829L18.3 0L13.0453 12.2829L0 13.413L10.2102 22.176L7.1434 35.2L18.3 28.2897Z"
                fill="#FFB618"
              />
            </svg>

            <div className={Bookcategoriesstyles.row}>
              <span className={Bookcategoriesstyles.price}>£{details.price}</span>
              {/* <div onClick={() => dispatch(addToCart(details))}>{details.cartBtn}</div> */}
              <div className={Bookcategoriesstyles.iconaddtocart} onClick={handleaddtocartsecond}>
                <CustomButton incoBg={false} icon={<FaShoppingCart />} iconwidth={'20px'} iconHeight={'20px'} outerDivBg={''} innerDivBg={details.btninnerDivBg} text={"Add to cart"} fontSize={"12px"} width={"125px"} height={"35px"} topGradient={details.btnouterDivBg} bottomGradient={details.btninnerDivBg} />
              </div>
            </div>
            <p className={Bookcategoriesstyles.paragraph}>
              {details.paragraph}
            </p>
          </div>
          <di className={Bookcategoriesstyles.column}>
            <div className={Bookcategoriesstyles.row1}>
              {/* <Thumbsup /> */}
              {/* {details.thumb} */}
              <FaRegThumbsUp style={{ fontSize: '24px', color: details.thumb }} />
              <span className={Bookcategoriesstyles.text}>
                {/* Suitable for any child that loves a colorful bed time story */}
                Suitable for any child who loves a colourful bedtime story. 
              </span>
            </div>
            <div className={Bookcategoriesstyles.row1}>
              {/* <Dilivered /> */}
              {/* {details.van} */}
              <TbTruckDelivery style={{ fontSize: '24px', color: details.van }} />
              <span className={Bookcategoriesstyles.text}>
                Printed and dispatched in 2-4 working days
              </span>
            </div>
          </di>
        </div>
      </div>
      <div className={Bookcategoriesstyles.astronautcharacter}>
        <img src={details.character} alt="astronautcharacter" />
      </div>
      <div style={{ backgroundImage: details.reviewsbg }} className={Bookcategoriesstyles.reviews}>
        <div className={Bookcategoriesstyles.doorbean}>
          <img src={details.reviewsimage} alt="doorbean" />
        </div>
        <Reviews CustomersReviews={details.CustomersReviews} reviewscolor={details.reviewscolor} />
      </div>
      <div style={{ backgroundImage: details.newletterbg }} className={Bookcategoriesstyles.newsletter}>
        <div style={details.reviewsimageposition} className={Bookcategoriesstyles.Astronautsfyling}>
          <img src={details.newsletterCharacter} alt="Astronautsfyling" />
        </div>
        <Newsletter btncolor={details.btncolor} newslettercolor={details.newslettercolor} />
      </div>
      <div className={Bookcategoriesstyles.ourbestsellers} style={{ position: 'relative' }}>
        <OurBestSellers titleColor={details.ourbestsellerTitlecolor} subheading={'#452565'} />
        <div style={{ backgroundColor: details.titlecolor }} className={Bookcategoriesstyles.viewallbooks} onClick={() => navigate('/our-books')}>
          See All <FaChevronRight style={{ fontSize: '12px' }} />
        </div>
      </div>
      <div className={Bookcategoriesstyles.footertopbanner}>
        <div style={{ backgroundImage: details.footerbg }} className={Bookcategoriesstyles.hills}></div>
      </div>
      <div className={Bookcategoriesstyles.Footer}>
        <Footer />
      </div>
    </div>
  );
};

export default Bookcategories;
