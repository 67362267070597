import React from "react";
import OurBooksMainstyle from "./OurBooksMain.module.css";
import "../../fonts.css";
import Footer from "../../Components/Footer/Footer";
import OurBooks from "../../Components/OurBooks/OurBooks";

const OurBooksMain = () => {
  return (
    <div className={OurBooksMainstyle.OurBooksMain}>
      <div className={OurBooksMainstyle.mainContainer}>
        <div className={OurBooksMainstyle.OurBooks}>
          <OurBooks titleColor={"#1F74C9"} />
        </div>
        <div className={OurBooksMainstyle.HomeContactus}>
          <div className={OurBooksMainstyle.Footer}>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurBooksMain;
