import React from 'react'
import OurBestSellersstyles from './OurBestSellers.module.css'
import book1Image from '../../../../Assets/BookCovers/Book1.webp'
import BookCard from '../../../../Components/BookCard/BookCard';

import book2Image from '../../../../Assets/BookCovers/docterboy.webp'
import book3Image from '../../../../Assets/BookCovers/firefightergirl.webp'
import book4Image from '../../../../Assets/BookCovers/youtubegirl.webp'

const booksData = [
    { bookId: 'astronaut', title: 'I Want To Be An Astronaut',rating: 5,category: 'astronaut', price: 23.99,  imageUrl: book1Image },
    { bookId: 'doctor', title: 'I Want To Be A Doctor', rating: 5,price: 23.99, category: 'doctor',  imageUrl: book2Image },
    { bookId: 'firefighter', title: 'I Want To Be A Firefighter',rating: 5, price: 23.99, category: 'firefighter', imageUrl: book3Image },
    { bookId: 'youtuber', title: 'I Want To Be A Youtuber',rating: 5, price: 23.99, category: 'youtuber',  imageUrl: book4Image }
    // Add more books as needed
  ];

const OurBestSellers = ({titleColor,subheading}) => {
  return (
    <div className={OurBestSellersstyles.main}>
        <h1 style={{color: titleColor}} className={OurBestSellersstyles.mainheading}>Our Bestsellers</h1>
        <p style={{color: subheading}} className={OurBestSellersstyles.subheading}>Personalised stories loved by millions</p>
      <div className={OurBestSellersstyles.bookContainer}>
        {booksData.map((book) => (
          // <BookCard
          //   key={book.bookId}
          //   title={book.title}
          //   price={book.price}
          //   author={book.author}
          //   imageUrl={book.imageUrl}
          // />
          <div key={book.bookId}>
            <BookCard
                  product={book}
                />
         </div>
        ))}
        
      </div>
    </div>
  )
}

export default OurBestSellers