import { useState } from "react"
import ProfileTopStyles from "./cartTop.module.css"
import CartItems from "../CartItems/CartItems"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import '../../../fonts.css';
import { setcolor, setIcon, setmessage, setsize, settoaststate, setuniqueId, setvariant } from "../../../features/toastSlice";
 
export default function CartTop({setAlert}) {
    const navigate = useNavigate();
    const {cart:{cart, totalPrice, totalCount, tax}} = useSelector((cart)=>cart)

    const dispatch = useDispatch();

    const handlenavigatecheckout = () => {

        if(cart.length > 0){
            navigate("/shippingAddress")
        }else{
            dispatch(settoaststate(true));
            dispatch(setsize('sm'));
            dispatch(setvariant('soft'));
            dispatch(setcolor('warning'));
            dispatch(setmessage('You do not have any item in cart'));
            dispatch(setIcon('warning'));
            dispatch(setuniqueId('000'))
        }
    }

    

    return (
        <div className={ProfileTopStyles.main}>
            <h1 className={ProfileTopStyles.mainheading}>Items In Your Cart</h1>
 
            <div className={ProfileTopStyles.bookContainer}>
                {/* left side */}
                <CartItems cart={cart} setAlert={setAlert} />
                {/* right side */}
 
                <div className={ProfileTopStyles.profileTopLeftContainer}>
                    <div className={ProfileTopStyles.profileTopLeft}>
                        <h3 className={ProfileTopStyles.leftTitle}>Cart Summary</h3>
                        <span className={ProfileTopStyles.labelText}>Items</span>
                        <div className={ProfileTopStyles.leftBtnsContainer}>
                            {totalCount}
                        </div>
 
                        <span className={ProfileTopStyles.labelText}>Postage and Packaging</span>
                        <div className={ProfileTopStyles.leftBtnsContainer}>
                            {cart.length > 0 ? `£ ${tax}` : "0"}
                        </div>
                        <span className={ProfileTopStyles.labelText}>Total</span>
                        {<div className={ProfileTopStyles.leftBtnsContainer}>
                            {cart.length > 0 ? `£ ${(totalPrice + tax).toFixed(2)}` : "0"}
                        </div>}
 
                    </div>
                    <div onClick={handlenavigatecheckout} className={ProfileTopStyles.leftBtnsContainer}>
                        <span className={ProfileTopStyles.checkoutText}>Checkout</span>
                    </div>
                </div>
 
 
            </div>
        </div>
    )
}
