import { createSlice } from "@reduxjs/toolkit";

function getNumericPrice(price) {
    if (typeof price === "string") {
        let numericPrice = price.replace(/[^0-9.]/g, '');
        return parseFloat(numericPrice) || 0; // Fallback to 0 if parsing fails
    }
    return Number(price) || 0; // Fallback to 0 if value is not a number
}

function calculateTax(totalCount) {
    if (totalCount === 1) return 3.99;
    if (totalCount === 2) return 4.49;
    if (totalCount === 3) return 5.49;
    if (totalCount >= 4 && totalCount <= 6) return 6.99;
    if (totalCount >= 7 && totalCount <= 10) return 8.99;
    if (totalCount > 10) return 0; // Direct to contact us for bulk purchases
    return 0;
}

// Load cart from localStorage
const loadCartFromLocalStorage = () => {
    const cartData = localStorage.getItem('cart');
    if (cartData) {
        return JSON.parse(cartData);
    }
    return {
        cart: [],
        totalPrice: 0,
        totalCount: 0,
        tax: 0,
    };
};

// Save cart to localStorage
const saveCartToLocalStorage = (state) => {
    localStorage.setItem('cart', JSON.stringify(state));
};

const initialState = loadCartFromLocalStorage();

export const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        addToCart: (state, action) => {
            const existingItemIndex = state.cart.findIndex(cartItem => cartItem.bookTempID === action.payload.bookTempID);
            if (existingItemIndex !== -1) {
                // Item already in cart, update quantity
                state.cart[existingItemIndex].quantity += 1;
            } else {
                // New item, add to cart
                state.cart.push({ ...action.payload, quantity: 1 });
            }
            state.totalCount += 1;
            const newPrice = Number(state.totalPrice) + getNumericPrice(action.payload.price);
            state.totalPrice = Number(newPrice.toFixed(2));
            state.tax = calculateTax(state.totalCount);
            saveCartToLocalStorage(state);
        },
        removeFromCart: (state, { payload }) => {
            const existingItemIndex = state.cart.findIndex(cartItem => cartItem.bookId === payload.bookId);
            if (existingItemIndex !== -1) {
                const item = state.cart[existingItemIndex];
                item.quantity -= 1;
                if (item.quantity <= 0) {
                    state.cart.splice(existingItemIndex, 1);
                }
                state.totalCount -= 1;
                const newPrice = Number(state.totalPrice) - getNumericPrice(payload.price);
                state.totalPrice = Number(newPrice.toFixed(2));
                state.tax = calculateTax(state.totalCount);
                saveCartToLocalStorage(state);
            }
        },
        clearcart: (state) => {
            // Clear the cart
            state.cart = [];
            state.totalCount = 0;
            state.totalPrice = 0;
            state.tax = 0;
            saveCartToLocalStorage(state);
        }
    }
});

export const { addToCart, removeFromCart, clearcart } = cartSlice.actions;
export default cartSlice.reducer;
