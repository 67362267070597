

import React,{useState} from 'react'
import ContactusHomestyles from './ContactusHome.module.css'
import { ReactComponent as Sendbtn } from '../../../../Assets/SVG/Sendbtn.svg';
import { useDispatch } from 'react-redux';
import { setcolor, setIcon, setmessage, setsize, settoaststate, setuniqueId, setvariant } from '../../../../features/toastSlice';
import CustomButton from '../../../../Components/CustomButton/CustomButton';
import { IoIosArrowForward } from 'react-icons/io';
import { TailSpin } from 'react-loader-spinner';
import { post } from 'aws-amplify/api';


const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
const ContactusHome = () => {
 const dispatch = useDispatch();

 const [contactusloading, setcontactusloading] = useState(null);

  const [fields, setFields] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
    subject: ""
  })
 
  // console.log("Fields : ", fields)
 
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
    subject: ""
  })
  // console.log("Errors : ", errors)
 
  const handleInputChange = (e) => {
    // console.log("key : ", e.target.name)

    setErrors({
      firstName: "",
      lastName: "",
      email: "",
      message: "",
      subject: ""
    })

    setFields((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }))
  }
 
  const handleErrors = (key, value) => {
    setErrors((prev) => ({
      ...prev,
      [key]: value
    }))
  }
 
  const handleSubmit =  async () => {
    let bol = true
    if (!fields.firstName) {
      handleErrors("firstName", "First Name Cannot be empty")
      bol = false
    }
    else {
      handleErrors("firstName", "")
      bol = true
    }
 
    if (!fields.lastName) {
      handleErrors("lastName", "Last Name Cannot be empty")
      bol = false
    }
    else {
      handleErrors("lastName", "")
      bol = true
    }
 
    if (!fields.email) {
      handleErrors("email", "Email Cannot be empty")
      bol = false
    }
    else {
      handleErrors("email", "")
      bol = true
    }
 
    if (!fields.message) {
      handleErrors("message", "Message Cannot be empty")
      bol = false
    }
    else {
      handleErrors("message", "")
      bol = true
    }
    if (!fields.subject) {
      handleErrors("subject", "Subject Cannot be empty")
      bol = false
    }
    else {
      handleErrors("message", "")
      bol = true
    }
    if (!bol) {
      return
    }
    setcontactusloading(true)
    try{
      const response = await fetch(`${apiBaseUrl}/items/contact-us`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          firstname: fields.firstName,
          lastname: fields.lastName,
          email: fields.email,
          subject: fields.subject,
          message: fields.message,
        })
      });

      // const response = post({
      //   apiName: 'apiBooks321',
      //   path: '/items/contact-us',
      //   options: {
      //     headers: {
      //       'Content-Type': 'application/json',
      //   },
      //     body: JSON.stringify({
      //     firstname: fields.firstName,
      //     lastname: fields.lastName,
      //     email: fields.email,
      //     subject: fields.bookName,
      //     message: fields.message,
      //   })
      //   }
      // });

      if(response.status === 200){
        setcontactusloading(false)
        setFields({
          firstName:"",
          lastName:"",
          email:"",
          message:"",
          subject:""
        })
        dispatch(settoaststate(true));
        dispatch(setsize('sm'));
        dispatch(setvariant('soft'));
        dispatch(setcolor('success'));
        dispatch(setmessage('Email sent succesfully'));
        dispatch(setIcon('success'));
        dispatch(setuniqueId(`homecontactus`))
      }
      // console.log("response",response)
      // console.log("response",response.status)
      
    }catch(error){
      console.log("error",error)
      setcontactusloading(false)
    }
  }
 
  return (
    <div className={ContactusHomestyles.main}>
      <h1 className={ContactusHomestyles.mainheading}>Contact Us</h1>
      <p className={ContactusHomestyles.paragraph}>
          We’re thrilled to have you drop by our website. Would LOVE to hear from you.
          Whether it’s an idea for a new book, some feedback on our website,
          or simply just to say “hi” then use the form below or email us at:  <a href="mailto:hello@iwant2bea.com" className={ContactusHomestyles.email}>hello@iwant2bea.com</a><br />
      </p>
      <div className={ContactusHomestyles.form}>
        <div className={ContactusHomestyles.row}>
          <div className={ContactusHomestyles.field}>
            <label htmlFor="firstName">First Name</label>
            <input onChange={handleInputChange} type="text"  value={fields.firstName} id="firstName" name="firstName" />
            {errors.firstName && <p className={ContactusHomestyles.error}>{errors.firstName}</p>}
          </div>
          <div className={ContactusHomestyles.field}>
            <label htmlFor="lastName">Last Name</label>
            <input onChange={handleInputChange} type="text" value={fields.lastName} id="lastName" name="lastName" />
            {errors.lastName && <p className={ContactusHomestyles.error}>{errors.lastName}</p>}

          </div>
        </div>
        <div className={ContactusHomestyles.row}>
          <div className={ContactusHomestyles.field}>
            <label htmlFor="email">Email</label>
            <input onChange={handleInputChange} type="email" value={fields.email} id="email" name="email" />
            {errors.email && <p className={ContactusHomestyles.error}>{errors.email}</p>}
 
          </div>
        </div>
        <div className={ContactusHomestyles.row}>
          <div className={ContactusHomestyles.field}>
            <label htmlFor="subject">Subject</label>
            <input onChange={handleInputChange} type="text" value={fields.subject} id="subject" name="subject" />
            {errors.subject && <p className={ContactusHomestyles.error}>{errors.subject}</p>}
 
          </div>
        </div>
        <div className={ContactusHomestyles.row}>
          <div className={ContactusHomestyles.field}>
            <label htmlFor="message">Message</label>
            <textarea onChange={handleInputChange} id="message" value={fields.message} name="message"></textarea>
            {errors.message && <p className={ContactusHomestyles.error}>{errors.message}</p>}
 
          </div>
        </div>
        <div className={ContactusHomestyles.rowbtn} onClick={handleSubmit}>
          {/* <Sendbtn type="submit" className={ContactusHomestyles.sendButton} /> */}
          
          {!contactusloading ? <CustomButton incoBg={true} icon={<IoIosArrowForward style={{width:"30px", height:"30px"}} />} outerDivBg={"#BB82F9"} innerDivBg={"#AA76E2"} text={"Send"} fontSize={"22px"} onClick={handleSubmit} width={"200px"} height={"60px"} topGradient={"rgb(156 102 212)"} bottomGradient={"rgb(189 158 220)"} />
          :
          <div className={ContactusHomestyles.homeCountactusform}>
          <TailSpin
            height="30"
            width="30"
            color="#fff"
            ariaLabel="tail-spin-loading"
            radius="2"
            visible={true}
          />
          </div>}
        </div>
      </div>
 

    </div>
  )
}
 
export default ContactusHome