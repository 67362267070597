// src/BookCard.js
import React, { useState } from 'react';
import BookCardStyle from './BookCard.module.css';
import { useDispatch } from "react-redux"
import { addToCart, removeFromCart } from '../../features/cart/cartSlice';
import { useNavigate } from 'react-router-dom';
import Stars from "../../SvgIcons/Stars/Stars"
import starsPng from "../../Assets/Images/siteImages/stars.webp"


import { setcolor, setIcon, setmessage, setsize, settoaststate, setuniqueId, setvariant } from '../../features/toastSlice';

const BookCard = ({ product }) => {
  // const [istoast, setIstoast] = useState({
  //   open:false,
  //   message: '',
  //   severity: ''
  // })
  const dispatch = useDispatch();
  const navigate = useNavigate();
  if (!product) {
    return
  }

  const addItemtocart = (product) => {

    if (product.category === "") {
      navigate(`/our-books/category/${product.category}`, { state: { imgURL: product.imageUrl } });
      return
    }




    const uniqueId = `${product.bookId}${Math.random()}`;
    // console.log("product", product)
    navigate('/createyourcharacter', { state: { productToRoute: product, uniqueIdToRoute: uniqueId } });

    // dispatch(addToCart(product));
    // dispatch(settoaststate(true));
    // dispatch(setsize('sm'));
    // dispatch(setvariant('soft'));
    // dispatch(setcolor('success'));
    // dispatch(setmessage('Item Added to you cart'));
    // dispatch(setIcon('success'));
    // dispatch(setuniqueId(`${product.bookId}${ran}`))





    // setIstoast({
    //   open: true,
    //   message: `${product.title} is added to cart`,
    //   severity: 'success'
    // })

  }
  
  const handleNavigate = () => {
    const path = `/our-books/category/${product.category}`;
    const state = { imgURL: product.imageUrl, routeProduct: product };
    if (product.category === "") {
      navigate(path, { state });
    } else {
      navigate(path, { state });
    }
  };


  // console.log("product...",product)
  return (
    <div className={BookCardStyle.card} key={product.bookId}  >

      <div className={BookCardStyle.imageContainer} onClick={handleNavigate}

      >
        <img src={product.imageUrl} alt={product.title} className={BookCardStyle.image} />
      </div>

      <div className={BookCardStyle.details}>
        <span className={BookCardStyle.title} onClick={handleNavigate}

        >{product.title}</span>
        {/* <div className={BookCardStyle.stars}>
            <p>By {product.author}</p>
          </div> */}
        {/* <svg
          className={BookCardStyle.stars}
              width="100"
              height="36"
              viewBox="0 0 231 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M163.9 28.2897L175.457 35.2L172.39 22.176L182.6 13.413L169.155 12.2829L163.9 0L158.645 12.2829L145.2 13.413L155.41 22.176L152.344 35.2L163.9 28.2897Z"
                fill="#FFB618"
              />
              <path
                d="M115.5 28.2897L127.056 35.2L123.99 22.176L134.2 13.413L120.754 12.2829L115.5 0L110.245 12.2829L96.7998 13.413L107.01 22.176L103.943 35.2L115.5 28.2897Z"
                fill="#FFB618"
              />
              <path
                d="M67.0999 28.2897L78.6565 35.2L75.5897 22.176L85.7999 13.413L72.3546 12.2829L67.0999 0L61.8452 12.2829L48.3999 13.413L58.6101 22.176L55.5433 35.2L67.0999 28.2897Z"
                fill="#FFB618"
              />
              <path
                d="M18.7 28.2897L30.2566 35.2L27.1898 22.176L37.4 13.413L23.9547 12.2829L18.7 0L13.4453 12.2829L0 13.413L10.2102 22.176L7.1434 35.2L18.7 28.2897Z"
                fill="#FFB618"
              />
              <path
                d="M231 13.413L217.555 12.2644L212.3 0L207.045 12.2829L193.6 13.413L203.81 22.176L200.743 35.2L212.3 28.2897L223.856 35.2L220.808 22.176L231 13.413ZM212.3 24.8253L205.269 29.0307L207.139 21.1015L200.93 15.7659L209.121 15.0619L212.3 7.59579L215.498 15.0804L223.688 15.7844L217.48 21.12L219.35 29.0493L212.3 24.8253Z"
                fill="#B6B6B6"
              />
            </svg> */}
        <Stars width='80' height='25' />
        {/* <img src={starsPng} alt="" /> */}
        <div className={BookCardStyle.priceCart}>
          <span className={BookCardStyle.price}>£{product.price}</span>
          <span className={BookCardStyle.cart} onClick={() => addItemtocart(product)}>Add to Cart</span>
        </div>
      </div>

      {/* <span>Link</span> */}
    </div>
  );
};

export default BookCard;
