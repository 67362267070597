import ProfileTopStyles from "./cartItems.module.css"
import book1Image from '../../../Assets/BookCovers/Book1.webp';
import starIcon from "../../../Assets/SVG/startSvg.svg"
import filledStar from "../../../Assets/SVG/starFilled.svg"
import { IoIosClose } from "react-icons/io";
import { removeFromCart, addToCart } from "../../../features/cart/cartSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import '../../../fonts.css';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import { setcolor, setIcon, setmessage, setsize, settoaststate, setuniqueId, setvariant } from "../../../features/toastSlice";

const stars = [starIcon, starIcon, starIcon, starIcon, starIcon,]

export default function CartItems({ cart, setAlert }) {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const today = new Date();  // Gets the current date and time
    const options = { year: 'numeric', month: 'long', day: '2-digit' };
    const formattedDate = today.toLocaleDateString('en-US', options);

    const ran = Math.random()
    const handleRemoveFromCart = (item) => {

        dispatch(removeFromCart(item))
        // setAlert({
        //     open: true,
        //     message: "Item Removed From Cart",
        //     severity:"error"
        // })
        dispatch(settoaststate(true));
        dispatch(setsize('sm'));
        dispatch(setvariant('soft'));
        dispatch(setcolor('danger'));
        dispatch(setmessage('Item Removed From Cart'));
        dispatch(setIcon('danger'));
        dispatch(setuniqueId(`${item.bookId}${ran}`))
    }

    const handleAddToCart = (details) => {

        dispatch(addToCart(details))
        // setAlert({
        //     open: true,
        //     message: "Item Added to cart.",
        //     severity:"success"
        // })
        dispatch(settoaststate(true));
        dispatch(setsize('sm'));
        dispatch(setvariant('soft'));
        dispatch(setcolor('success'));
        dispatch(setmessage('Item Added to you cart'));
        dispatch(setIcon('success'));
        dispatch(setuniqueId(`${details.bookId}${ran}`))
    }

    return (
        <div className={ProfileTopStyles.profileTopRight}>
            <h1 className={ProfileTopStyles.rightTitle}>Items</h1>
            {cart.length > 0 ? (
                <div className={ProfileTopStyles.profileRightContent}>
                    {cart.map((item, index) => (
                        <div key={index} className={ProfileTopStyles.orderItem}>
                            <div className={ProfileTopStyles.imgContainer}>
                                <img src={item.imageUrl} alt={item.title} className={ProfileTopStyles.itemImg} />
                            </div>
                            <div className={ProfileTopStyles.itemDetailContainer}>
                                <div className={ProfileTopStyles.itemTop}>
                                    <div className={ProfileTopStyles.itemTitleContainer}>
                                        <p className={ProfileTopStyles.itemTitle}>{item.title}</p>
                                        {/* <span className={ProfileTopStyles.closeBtnContainer} onClick={() => handleRemoveFromCart(item)}>
                                            <IoIosClose className={ProfileTopStyles.closeBtn} />
                                        </span> */}
                                    </div>
                                    <div className={ProfileTopStyles.starsContainer}>
                                        {Array.from({ length: item.rating }, (_, index) => (
                                            <img key={index} src={filledStar} alt="Star" style={{ color: "yellow" }} />
                                        ))}
                                        {stars.slice(item.rating, 5).map((star, index) => (
                                            <img key={index} src={star} alt="Star" style={{ color: "yellow" }} />
                                        ))}
                                    </div>
                                    <div className={ProfileTopStyles.variations}>
                                        <div className={ProfileTopStyles.columns}>
                                            <ul>
                                                <li><strong>Gender:</strong> {item?.variation?.gender}</li>
                                                <li><strong>Eye Color:</strong> {item?.variation?.['eye-color']}</li>
                                                <li><strong>Skin Color:</strong> {item?.variation?.['skin-color']}</li>
                                                <li><strong>Hair Style:</strong> {item?.variation?.['hair-style']}</li>
                                            </ul>
                                            <ul>
                                                <li><strong>Hair Color:</strong> {item?.variation?.['hair-color']}</li>
                                                <li><strong>Town:</strong> {item?.variation?.town}</li>
                                                <li><strong>Child Name:</strong> {item?.variation?.['child-name']}</li>
                                            </ul>

                                        </div>
                                    </div>
                                </div>
                                <div className={ProfileTopStyles.itemBottom}>
                                    <div className={ProfileTopStyles.price_date_container}>
                                        <span className={ProfileTopStyles.priceContainer}>
                                            Price: £<span className={ProfileTopStyles.price}>{item.price}</span>
                                        </span>
                                        <span className={ProfileTopStyles.date}>Ordered: {formattedDate}</span>
                                    </div>
                                    <div className={ProfileTopStyles.quantity}>
                                        <span className={ProfileTopStyles.quantity_btn} onClick={() => handleRemoveFromCart(item)}>-</span>
                                        <span className={ProfileTopStyles.quantity_amount}>{item.quantity}</span>
                                        <span className={ProfileTopStyles.quantity_btn} onClick={() => handleAddToCart(item)}>+</span>
                                    </div>
                                    {/* <div className={ProfileTopStyles.variations}>
                                        <h4>Variations:</h4>
                                        <ul>
                                            <li><strong>Gender:</strong> {item.variation.gender}</li>
                                            <li><strong>Eye Color:</strong> {item.variation['eye-color']}</li>
                                            <li><strong>Skin Color:</strong> {item.variation['skin-color']}</li>
                                            <li><strong>Hair Style:</strong> {item.variation['hair-style']}</li>
                                            <li><strong>Hair Color:</strong> {item.variation['hair-color']}</li>
                                            <li><strong>Town:</strong> {item.variation.town}</li>
                                            <li><strong>Child Name:</strong> {item.variation['child-name']}</li>
                                        </ul>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className={ProfileTopStyles.profileRightContent}>
                    <h4 className={ProfileTopStyles.rightSubTitle}>Cart is Empty</h4>
                    <ProductionQuantityLimitsIcon style={{ fontSize: '30px', color: 'gray' }} />
                    <div className={ProfileTopStyles.leftBtnsContainer}>
                        <p className={ProfileTopStyles.rightPara}>Go to Our Books and explore</p>
                        <button onClick={() => navigate('/our-books')} className={ProfileTopStyles.leftBtns} style={{ padding: "5px 0" }}>
                            Our Books
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}